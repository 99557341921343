import { push } from 'redux-first-history';
import { change, reset, SubmissionError } from 'redux-form';

import service from 'modules/service';
import { ingestionApiDefinitions } from 'modules/service/types';
import {
    EVENT_CATEGORY,
    HTTP_CODE,
    LOAD_STATUS,
    MANIFEST_FIELD_TYPE,
    PERMISSIONS,
    SCHEDULE_FREQUENCY,
    SECRET_VALUE_PLACEHOLDER,
    SORT_ORDER,
    TEMP_ID_PREFIX
} from 'modules/common/constants';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import {
    FEED_VERSION_STATE,
    FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER,
    FEED_STATE,
    FEEDS_ROUTES,
    AD_HOC_RUN_REPORT_RANGE,
    METHOD_SWITCH_OPTIONS_VALUES
} from '../constants';
import { intl } from 'modules/i18n';
import {
    confirm,
    formatString,
    prepareFormErrors,
    prepareFormValues,
    showErrorSnackbar,
    getErrorText,
    generateCronExpression,
    prefixManifestErrors
} from 'modules/common/utils';
import { getRouterPath } from 'modules/common/selectors';
import { removeSubmitError } from 'modules/form/actions';

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

export const getFeedsList = (append = false) => async (dispatch, getState) => {
    type ApiResponseType = ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoFeedSearchResponseDto'];

    dispatch(storeActions.setFeedsListLoadStatus(LOAD_STATUS.LOADING));

    const clientId = getSelectedClientId(getState());
    let list: ApiResponseType | null = null;

    try {
        const payload = selectors.getFeedsListSearchPayload(getState());
        list = await service.api.getFeedsList(clientId, payload) as ApiResponseType;
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    }

    if (clientId === getSelectedClientId(getState())) { // don't set list data if client was changed during the API call
        if (list) {
            append ?
                dispatch(storeActions.appendFeedsListItems(list.objects)) :
                dispatch(storeActions.setFeedsListItems(list.objects));

            dispatch(storeActions.setFeedsListCount(list.totalCount));
        }

        dispatch(storeActions.setFeedsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreFeeds = () => async (dispatch, getState) => {
    const page = selectors.getFeedsListPage(getState()) + 1;

    dispatch(storeActions.setFeedsListPage(page));
    await dispatch(getFeedsList(true));

    service.analytics.trackEvent('Load more items', EVENT_CATEGORY.FEEDS);
};

export const feedsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getFeedsListSortColumn(storeState);
    const currentSortOrder = selectors.getFeedsListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setFeedsListSortOrder(order));
    } else {
        dispatch(storeActions.setFeedsListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setFeedsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setFeedsListPage(0));
    dispatch(storeActions.setFeedsListItems([]));
    dispatch(storeActions.setFeedsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('List sort', EVENT_CATEGORY.FEEDS);
};

export const feedsListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getFeedsListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setFeedsListPage(0));
        dispatch(storeActions.setFeedsListItems([]));
        dispatch(storeActions.setFeedsListSearchText(text));
        dispatch(storeActions.setFeedsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('List search', EVENT_CATEGORY.FEEDS);
    }
};

export const goToFeedDetailsPage = (id) => (dispatch, getState) => {
    const clientId = getSelectedClientId(getState());

    dispatch(push(formatString(FEEDS_ROUTES.DETAILS, clientId, id)));
};

export const goToAddFeedPage = () => (dispatch) => {
    dispatch(push(FEEDS_ROUTES.NEW));
};

export const goToEditFeedPage = (clientId, feedId) => (dispatch) => {
    dispatch(push(formatString(FEEDS_ROUTES.EDIT, clientId, feedId)));
};

export const goToPendingFeedPage = (clientId, feedId) => (dispatch) => {
    dispatch(push(formatString(FEEDS_ROUTES.PENDING, clientId, feedId)));
};

export const goToFeedsListPage = () => (dispatch) => {
    dispatch(push(FEEDS_ROUTES.LIST));
};

const getCollectionOffsetTemplateDescription = async (id) => {
    const messageId = `collectionOffsetTemplate.${id}`;

    if (intl.messages[messageId]) {
        return intl.formatMessage({ id: messageId });
    } else {
        try {
            const collectionOffsetTemplate: ingestionApiDefinitions['CollectionOffsetTemplateDetailResponseDto'] = await service.api.getCollectionOffsetTemplateById(id);

            return collectionOffsetTemplate.description;
        } catch (err) {
            showErrorSnackbar(getErrorText(err));

            return '-';
        }
    }
};

const populateCollectionOffsetTemplateDescriptions = async (details) => {
    // this loop should be fine in most cases as API call is only made as a fallback option in case when template
    // name is not found in translations map, which should be a rare case
    for (const schedule of (details?.schedules || [])) {
        schedule.collectionOffsetTemplateDescription = await getCollectionOffsetTemplateDescription(schedule.collectionOffsetTemplateId);
    }
};

export const getFeedDetails = (clientId, feedId) => async (dispatch) => {
    dispatch(storeActions.setFeedDetailsLoadStatus(LOAD_STATUS.LOADING));

    let feedDetailsAvailable = true;
    let details;

    try {
        details = await service.api.getCurrentFeedVersionDetails(clientId, feedId);
        details.targets?.sort(({ targetName: name1 }, { targetName: name2 }) => (name1 > name2 ? 1 : -1));

        await populateCollectionOffsetTemplateDescriptions(details);

        dispatch(storeActions.setFeedDetails(details));
    } catch (err) {
        feedDetailsAvailable = false;
        showErrorSnackbar(getErrorText(err));
    }

    if (feedDetailsAvailable && details.state === FEED_VERSION_STATE.VALID) {
        // if feed version is in VALID state, check if another version exists
        try {
            const additionalDetails = await service.api.getCurrentFeedVersionDetails(clientId, feedId, true);
            await populateCollectionOffsetTemplateDescriptions(additionalDetails);

            dispatch(storeActions.setAdditionalFeedDetails(additionalDetails));
        } catch (err) {
            if (!err.response || err.response.status !== HTTP_CODE.NOT_FOUND) {
                // ignore the error in case of 404, it means that only valid feed version exists
                showErrorSnackbar(getErrorText(err));
            }
        }
    }

    dispatch(storeActions.setFeedDetailsLoadStatus(LOAD_STATUS.LOADED));
};

export const getFeedDataForEditing = (clientId, feedId) => async (dispatch, getState) => {
    await dispatch(getFeedDetails(clientId, feedId));

    const { schedules, targets } = selectors.getFeedDetails(getState());

    if (schedules?.length) {
        dispatch(storeActions.setFeedSchedules(schedules.map(({ id }) => ({ id }))));
    }

    if (targets?.length) {
        dispatch(storeActions.setFeedTargets(targets.map(({ id }) => ({ id }))));
    }
};

export const getFeedDetailsNonExcludingValidVersion = (clientId, feedId) => async (dispatch) => {
    try {
        const details = await service.api.getCurrentFeedVersionDetails(clientId, feedId);
        await populateCollectionOffsetTemplateDescriptions(details);

        dispatch(storeActions.setFeedDetails(details));

        return details;
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    }
};

export const getFeedDetailsExcludingValidVersion = (clientId, feedId) => async (dispatch) => {
    try {
        const additionalDetails = await service.api.getCurrentFeedVersionDetails(clientId, feedId, true);
        await populateCollectionOffsetTemplateDescriptions(additionalDetails);

        dispatch(storeActions.setAdditionalFeedDetails(additionalDetails));

        return additionalDetails;
    } catch (err) {
        if (!err.response || err.response.status !== HTTP_CODE.NOT_FOUND) {
            // ignore the error in case of 404, it means that only valid feed version exists
            showErrorSnackbar(getErrorText(err));
        }
    }
};

export const togglePauseFeedSchedule = (feedId, schedulerPaused = false) => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setFeedDetailsOperationInProgress(true));
        dispatch(storeActions.setFeedDetailsOperationInProgressReporterData({
            reporter: FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER.TOGGLE_PAUSE,
            prevStateData: {
                schedulerPaused
            }
        }));

        const clientId = getSelectedClientId(getState());
        const togglePauseFeedScheduleAPI = schedulerPaused ? service.api.feedScheduleRestart : service.api.feedSchedulePause;

        await togglePauseFeedScheduleAPI(clientId, feedId);

        const details: ingestionApiDefinitions['FeedVersionDetailResponseDto'] = await dispatch(getFeedDetailsNonExcludingValidVersion(clientId, feedId));

        service.analytics.trackEvent(schedulerPaused ? 'Restart feed schedule' : 'Pause feed schedule', EVENT_CATEGORY.FEEDS);

        if (details && details.state === FEED_VERSION_STATE.VALID) {
            return dispatch(getFeedDetailsExcludingValidVersion(clientId, feedId));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedDetailsOperationInProgress(false));
        dispatch(storeActions.unsetFeedDetailsOperationInProgressReporterData());
    }
};

export const toggleEnableFeed = (feedId, feedState) => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setFeedDetailsOperationInProgress(true));
        dispatch(storeActions.setFeedDetailsOperationInProgressReporterData({ reporter: FEED_DETAILS_OPERATION_IN_PROGRESS_REPORTER.TOGGLE_STATE }));

        const clientId = getSelectedClientId(getState());

        const isEnabled = feedState === FEED_STATE.ENABLED;
        const toggleEnableFeedAPI = isEnabled ? service.api.feedDisable : service.api.feedEnable;

        await toggleEnableFeedAPI(clientId, feedId);

        service.analytics.trackEvent(isEnabled ? 'Disable feed' : 'Enable feed', EVENT_CATEGORY.FEEDS);

        const details: ingestionApiDefinitions['FeedVersionDetailResponseDto'] = await dispatch(getFeedDetailsNonExcludingValidVersion(clientId, feedId));

        if (details && details.state === FEED_VERSION_STATE.VALID) {
            return dispatch(getFeedDetailsExcludingValidVersion(clientId, feedId));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedDetailsOperationInProgress(false));
        dispatch(storeActions.unsetFeedDetailsOperationInProgressReporterData());
    }
};

const validateFeedFormValues = (getState, formValues) => {
    const errors: any = {};
    const requiredFields = [ 'suffix', 'sourceId', 'collectorId', 'credentialId' ];

    const { method, schedules, createNewDatastream } = formValues;

    if (createNewDatastream) {
        requiredFields.push('adverityAuthorizationId');
    }

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    const targets = selectors.getFeedFormSelectedTargets(getState());
    const targetsErrors = {};

    targets.forEach((target, idx) => {
        const targetId = `target_${idx}`;

        const targetErrors: any = {};
        if (!formValues.targets[targetId]) {
            targetErrors.targetId = 'custom.Required';
        } else if (!formValues.targets[targetId].targetCredentialId) {
            targetErrors.targetCredentialId = 'custom.Required';
        }

        if (Object.keys(targetErrors).length) {
            targetsErrors[targetId] = targetErrors;
        }
    });

    if (Object.keys(targetsErrors).length) {
        errors.targets = targetsErrors;
    }

    if (method === METHOD_SWITCH_OPTIONS_VALUES.SCHEDULE) {
        if (!schedules) {
            errors.schedules = 'custom.ScheduleRequired';
        } else {
            for (const key in schedules) {
                if (schedules.hasOwnProperty(key)) {
                    const schedule = schedules[key];
                    const requiredScheduleFields = ['collectionOffsetTemplateId', 'collectionOffsetDays'];

                    const scheduleErrors: any = {};
                    requiredScheduleFields.forEach((fieldName) => {
                        if (schedule[fieldName] === undefined || schedule[fieldName] === null) {
                            scheduleErrors[fieldName] = 'custom.Required';
                        }
                    });

                    const { cron: { frequency } } = schedule;
                    const requiredCronFields: string[] = [];

                    if (frequency === SCHEDULE_FREQUENCY.CUSTOM) {
                        requiredCronFields.push('expression');
                    } else {
                        requiredCronFields.push('hour');
                        requiredCronFields.push('minute');

                        if (frequency === SCHEDULE_FREQUENCY.WEEKLY) {
                            requiredCronFields.push('weekday');
                        } else if (frequency === SCHEDULE_FREQUENCY.MONTHLY) {
                            requiredCronFields.push('dayOfMonth');
                        }
                    }

                    const cronErrors = {};
                    requiredCronFields.forEach((fieldName) => {
                        if (schedule.cron[fieldName] === undefined || schedule.cron[fieldName] === null) {
                            cronErrors[fieldName] = 'custom.Required';
                        }
                    });

                    if (Object.keys(cronErrors).length) {
                        scheduleErrors.cron = cronErrors;
                    }

                    if (Object.keys(scheduleErrors).length) {
                        if (!errors.schedules) {
                            errors.schedules = {};
                        }

                        errors.schedules[key] = scheduleErrors;
                    }
                }
            }
        }
    }

    return Object.keys(errors).length ? errors : undefined;
};

const prepareFeedFormErrors = (data) => {
    data.validationErrors = data.validationErrors.map(({ code, field, description }) => ({
        code,
        description,
        field: field.endsWith('cron') ? field.replace('cron', 'cron.expression') : field
    }));

    return prepareFormErrors(data);
};

export const addFeed = (formValues) => async (dispatch, getState) => {
    const errors = validateFeedFormValues(getState, formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setFeedFormOperationInProgress(true));

    const {
        name,
        suffix,
        credentialId,
        sourceVersionCollectorConfigurationId
    } = formValues;

    const {
        clientId,
        configurationContext,
        schedules,
        targets,
        adverityAuthorizationId
    } = prepareFeedData(getState, formValues);

    try {
        const feedDetails = await service.api.addFeed(
            clientId,
            {
                name,
                suffix,
                credentialId,
                sourceVersionCollectorConfigurationId,
                configurationContext,
                schedules,
                targets,
                adverityAuthorizationId
            }
        );

        service.analytics.trackEvent('Create feed', EVENT_CATEGORY.FEEDS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === FEEDS_ROUTES.NEW) {
            dispatch(goToFeedDetailsPage(feedDetails.feedId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFeedFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setFeedFormOperationInProgress(false));
    }
};

export const editFeed = (feedId, formValues) => async (dispatch, getState) => {
    const errors = validateFeedFormValues(getState, formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    const { usedByOdpReportingApi, linkedReports } = selectors.getFeedDetails(getState());
    const confirmation = usedByOdpReportingApi ?
        await confirm({
            title: intl.formatMessage({ id: 'feeds.editFeed' }),
            messages: [ formatString(intl.formatMessage({ id: 'feeds.editFeedConfirmationMessage' }), linkedReports?.length) ],
            cancelButtonClass: 'btn-flat'
        }) :
        true;

    if (!confirmation) {
        return;
    }

    dispatch(storeActions.setFeedFormOperationInProgress(true));

    const {
        name,
        credentialId,
        sourceVersionCollectorConfigurationId
    } = formValues;

    const { clientId, configurationContext, schedules, targets } = prepareFeedData(getState, formValues);

    try {
        await service.api.editFeed(
            clientId,
            feedId,
            {
                configurationContext,
                credentialId,
                schedules,
                targets,
                name,
                sourceVersionCollectorConfigurationId
            }
        );

        service.analytics.trackEvent('Edit feed', EVENT_CATEGORY.FEEDS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if ([ formatString(FEEDS_ROUTES.EDIT, clientId, feedId), formatString(FEEDS_ROUTES.PENDING, clientId, feedId) ].includes(path)) {
            dispatch(goToFeedDetailsPage(feedId));
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFeedFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setFeedFormOperationInProgress(false));
    }
};

const prepareFeedData = (getState, formValues) => {
    const state = getState();

    const clientId = getSelectedClientId(state);
    const manifest = selectors.getCollectorConfigurationManifest(state);
    const scheduleIds = selectors.getFeedFormSchedules(state);

    const { collectorId, schedules, targets } = formValues;

    const mandatoryDefaultValues = manifest.panels.reduce((accumulator, currentValue) => {
        currentValue.fields.filter(({ nullable }) => !nullable).forEach(({ id, defaultValue }) => {
            accumulator[`${currentValue.id}.${id}`] = defaultValue;
        });

        return accumulator;
    }, {});

    const configurationContext = {
        ...mandatoryDefaultValues,
        ...prepareFormValues(manifest, formValues[collectorId.split('_')[0]])
    };

    const processedSchedules = Object.values<any>(schedules || {}).map(({ collectionOffsetDays, collectionOffsetTemplateId, cron }, index) => {
        const schedule: any = {
            collectionOffsetDays,
            collectionOffsetTemplateId,
            cron: generateCronExpression(cron)
        };

        const scheduleId = scheduleIds[index].id;
        if (!scheduleId.startsWith(TEMP_ID_PREFIX)) {
            schedule.id = scheduleId;
        }

        return schedule;
    });

    let adverityAuthorizationId: number | null = null;
    if (formValues.createNewDatastream) {
        delete configurationContext['datastream.datastream-id'];
        adverityAuthorizationId = formValues.adverityAuthorizationId;
    }

    return {
        clientId,
        configurationContext,
        adverityAuthorizationId,
        schedules: processedSchedules.length ? processedSchedules : null,
        targets: targets ? Object.values(targets) : []
    };
};

export const getSourcesList = () => async (dispatch, getState) => {
    dispatch(storeActions.setFeedFormSourcesLoadStatus(LOAD_STATUS.LOADING));

    try {
        const { owners } = getUserProfile(getState());

        if (owners.length) {
            const response = await service.api.getSourcesList(true);

            dispatch(storeActions.setFeedFormSources(response
                .filter(({ ownerId }) => {
                    const owner = owners.find(({ id }) => id === ownerId);

                    return owner.permissions.includes(PERMISSIONS.SOURCES.USE);
                })
            ));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormSourcesLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getCollectorCredentialsList = () => async (dispatch) => {
    dispatch(storeActions.setFeedFormCollectorCredentialsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response = await service.api.getCollectorCredentialsList();
        dispatch(storeActions.setFeedFormCollectorCredentials(response.objects));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormCollectorCredentialsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getSourceDetails = (ownerId, sourceId, sourceVersionId = null) => async (dispatch) => {
    dispatch(storeActions.setFeedFormSourceDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const details: ingestionApiDefinitions['SourceVersionDetailDto'] = sourceVersionId ?
            await service.api.getSourceVersionDetails(ownerId, sourceId, sourceVersionId) :
            await service.api.getCurrentSourceVersionDetails(ownerId, sourceId);

        dispatch(storeActions.setFeedFormSourceDetails(details));

        if (details.dataCollectorConfigurations?.some(({ collectorId }) => collectorId === REACT_APP_ADVERITY_COLLECTOR_ID)) {
            dispatch(storeActions.setFeedFormAdverityAuthorizationsLoadStatus(LOAD_STATUS.REQUIRED));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormSourceDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getCollectorConfigurationManifest = (id) => async (dispatch) => {
    dispatch(storeActions.setFeedFormCollectorConfigurationManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const manifest = await service.api.getCollectorConfigurationManifest(id);
        dispatch(storeActions.setFeedFormCollectorConfigurationManifest(manifest));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormCollectorConfigurationManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getCollectionOffsetTemplate = () => async (dispatch) => {
    dispatch(storeActions.setFeedCollectionOffsetTemplateLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response = await service.api.getCollectionOffsetTemplate();
        dispatch(storeActions.setFeedCollectionOffsetTemplate(response.objects));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedCollectionOffsetTemplateLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const runFeed = (feedId, versionId, formValues) => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setFeedDetailsOperationInProgress(true));

        const storeState = getState();
        const {
            reportRangeType,
            collectionOffsetTemplateId,
            collectionOffsetDays,
            collectionStartDate,
            collectionEndDate,
            configurationOverrides
        } = formValues;

        const manifest = selectors.getCollectorConfigurationManifest(storeState);

        const { collectorId } = selectors.getFeedDetails(storeState);
        const { dataCollectorConfigurations } = selectors.getFeedFormSourceDetails(storeState);
        const { configurationContext } = dataCollectorConfigurations.find((collectorConfiguration) => collectorConfiguration.collectorId === collectorId);

        const contextOverrideParameters = {};
        manifest.panels.forEach(({ id: panelId, fields }) => {
            fields.forEach((config) => {
                const fieldIsVisible = !config.dependOn || (config.dependOn && config.dependOnValues.includes(configurationOverrides[panelId][config.dependOn]));

                const key = `${panelId}.${config.id}`;
                const override = fieldIsVisible && configurationContext.find((item) => item.key === key)?.overridable;

                if (override) {
                    if (configurationOverrides[panelId].hasOwnProperty(config.id)) {
                        if ((config.type !== MANIFEST_FIELD_TYPE.PASSWORD) ||
                            (config.type === MANIFEST_FIELD_TYPE.PASSWORD && configurationOverrides[panelId][config.id] !== SECRET_VALUE_PLACEHOLDER)) {
                            contextOverrideParameters[key] = configurationOverrides[panelId][config.id];
                        }
                    } else {
                        if (config.type === MANIFEST_FIELD_TYPE.CHECKBOX) {
                            contextOverrideParameters[key] = false;
                        } else {
                            contextOverrideParameters[key] = '';
                        }
                    }
                }
            });
        });

        const payload = reportRangeType === AD_HOC_RUN_REPORT_RANGE.COLLECTION_OFFSET_TEMPLATE ? {
            collectionOffsetTemplateId,
            collectionOffsetDays,
            contextOverrideParameters
        } : {
            collectionStartDate: collectionStartDate.format('yyyy-MM-DD'),
            collectionEndDate: collectionEndDate.format('yyyy-MM-DD'),
            contextOverrideParameters
        };

        const clientId = getSelectedClientId(storeState);

        await service.api.runFeed(clientId, feedId, versionId, payload);

        service.analytics.trackEvent('Run feed', EVENT_CATEGORY.FEEDS);

        const details: ingestionApiDefinitions['FeedVersionDetailResponseDto'] = await dispatch(getFeedDetailsNonExcludingValidVersion(clientId, feedId));

        if (details && details.state === FEED_VERSION_STATE.VALID) {
            await dispatch(getFeedDetailsExcludingValidVersion(clientId, feedId));
        }

        dispatch(storeActions.closeAdHocRunDialog());
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'feeds.feedRunValidationFailedMessage' }));

            data.validationErrors = prefixManifestErrors(data.validationErrors, 'configurationOverrides');

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setFeedDetailsOperationInProgress(false));
    }
};

export const showFeedDeleteDialog = (clientId, feedId) => async (dispatch) => {
    try {
        dispatch(storeActions.setFeedDetailsOperationInProgress(true));

        const { instances } = await service.api.getInstancesByTriggerId(clientId, { feedId });

        dispatch(storeActions.setFeedDeleteDialogDependentInstances(instances));
        dispatch(storeActions.openFeedDeleteDialog());
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedDetailsOperationInProgress(false));
    }
};

export const deleteFeed = (clientId, feedId) => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setFeedDetailsOperationInProgress(true));

        await service.api.deleteFeed(clientId, feedId);

        service.analytics.trackEvent('Delete feed', EVENT_CATEGORY.FEEDS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(FEEDS_ROUTES.DETAILS, clientId, feedId)) {
            dispatch(goToFeedsListPage());
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedDetailsOperationInProgress(false));
    }
};

export const loadAdHocRunConfigurationData = () => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setFeedFormCollectorConfigurationManifestLoadStatus(LOAD_STATUS.LOADING));
        dispatch(storeActions.setFeedFormSourceDetailsLoadStatus(LOAD_STATUS.LOADING));

        const { sourceId, sourceOwnerId, sourceVersionId, collectorId } = selectors.getFeedDetails(getState());
        const [manifest, sourceDetails] = await Promise.all([
            service.api.getCollectorConfigurationManifest(collectorId),
            service.api.getSourceVersionDetails(sourceOwnerId, sourceId, sourceVersionId)
        ]);

        dispatch(storeActions.setFeedFormCollectorConfigurationManifest(manifest));
        dispatch(storeActions.setFeedFormSourceDetails(sourceDetails));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormCollectorConfigurationManifestLoadStatus(LOAD_STATUS.LOADED));
        dispatch(storeActions.setFeedFormSourceDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const goToFeedRunsPage = () => (dispatch, getState) => {
    dispatch(push(formatString(FEEDS_ROUTES.RUNS, getSelectedClientId(getState()))));
};

export const getFeedRuns = () => async (dispatch, getState) => {
    dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();

        const clientId = getSelectedClientId(storeState);
        const payload = selectors.getFeedRunsListSearchPayload(storeState);

        const { objects, totalCount }: ingestionApiDefinitions['ApiPaginatedCollectionContainerDtoFeedRunsResponseDto'] = await service.api.getFeedRuns(clientId, payload);

        dispatch(storeActions.appendFeedRunsListItems(objects));
        dispatch(storeActions.setFeedRunsListCount(totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreFeedRuns = () => async (dispatch, getState) => {
    const { page } = selectors.getFeedRunsListData(getState());

    dispatch(storeActions.setFeedRunsListPage(page + 1));
    await dispatch(getFeedRuns());

    service.analytics.trackEvent('Load more items', EVENT_CATEGORY.FEED_RUNS);
};

export const feedRunsListSearch = (text) => (dispatch, getState) => {
    const { searchString } = selectors.getFeedRunsListData(getState());

    if (text !== searchString) {
        dispatch(storeActions.setFeedRunsListPage(0));
        dispatch(storeActions.setFeedRunsListItems([]));
        dispatch(storeActions.setFeedRunsListSearchText(text));
        dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('List search', EVENT_CATEGORY.FEED_RUNS);
    }
};

export const applyFeedRunsFilters = (status, fetchStart, fetchEnd, runDate) => (dispatch) => {
    dispatch(storeActions.setFeedRunsFiltersStatus(status));
    dispatch(storeActions.setFeedRunsFiltersFetchStart(fetchStart));
    dispatch(storeActions.setFeedRunsFiltersFetchEnd(fetchEnd));
    dispatch(storeActions.setFeedRunsFiltersRunDate(runDate));

    dispatch(storeActions.setFeedRunsListPage(0));
    dispatch(storeActions.setFeedRunsListItems([]));
    dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('Apply list filters', EVENT_CATEGORY.FEED_RUNS);
};

export const getFeedRunsByStatus = (newStatus) => (dispatch, getState) => {
    const { status } = selectors.getFeedRunsListFilters(getState());

    if (status !== newStatus) {
        dispatch(storeActions.setFeedRunsFiltersStatus(newStatus));
        dispatch(storeActions.setFeedRunsListPage(0));
        dispatch(storeActions.setFeedRunsListItems([]));
        dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('Apply list filters', EVENT_CATEGORY.FEED_RUNS);
    }
};

export const feedRunsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getFeedRunsListData(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setFeedRunsListSortOrder(order));
    } else {
        dispatch(storeActions.setFeedRunsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setFeedRunsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setFeedRunsListPage(0));
    dispatch(storeActions.setFeedRunsListItems([]));
    dispatch(storeActions.setFeedRunsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('List sort', EVENT_CATEGORY.FEED_RUNS);
};

export const removeSchedule = (index) => (dispatch, getState) => {
    dispatch(storeActions.removeFeedSchedule(index));
    dispatch(removeSubmitError({
        form: 'feedForm',
        field: 'schedules'
    }));

    const storeState = getState();
    const { length } = selectors.getFeedFormSchedules(storeState);
    const { schedules } = selectors.getFeedFormValues(storeState);

    if (schedules) {
        for (let i = index; i < length; i++) {
            dispatch(change('feedForm', `schedules.schedule_${i}`, schedules[`schedule_${i + 1}`]));
        }

        dispatch(change('feedForm', `schedules.schedule_${length}`, undefined));
    }
};

export const resetFeedFormDataAndValues = () => (dispatch) => {
    dispatch(reset('feedForm'));
    dispatch(storeActions.resetFeedForm());
};

export const getTargetsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setFeedFormTargetsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const { owners } = getUserProfile(getState());

        if (owners.length) {
            const { objects } = await service.api.getTargetsList();

            dispatch(storeActions.setFeedFormTargets(objects
                .reduce((acc, target) => {
                    const owner = owners.find(({ id }) => id === target.ownerId);

                    if (owner?.permissions.includes(PERMISSIONS.TARGETS.USE)) {
                        acc.push({ ...target, ownerName: owner.name });
                    }

                    return acc;
                }, [])
            ));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormTargetsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getTargetCredentialsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setFeedFormTargetCredentialsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const { owners } = getUserProfile(getState());

        if (owners.length) {
            const { objects } = await service.api.getTargetCredentialsList();

            dispatch(storeActions.setFeedFormTargetCredentials(objects
                .reduce((acc, credential) => {
                    const owner = owners.find(({ id }) => id === credential.ownerId);

                    if (owner?.permissions.includes(PERMISSIONS.TARGET_CREDENTIALS.USE)) {
                        acc.push({ ...credential, ownerName: owner.name });
                    }

                    return acc;
                }, [])
            ));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormTargetCredentialsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const removeTarget = (index) => (dispatch, getState) => {
    dispatch(storeActions.removeFeedTarget(index));
    dispatch(removeSubmitError({
        form: 'feedForm',
        field: 'targets'
    }));

    const storeState = getState();
    const { length } = selectors.getFeedFormSelectedTargets(storeState);
    const { targets } = selectors.getFeedFormValues(storeState);

    if (targets) {
        for (let i = index; i < length; i++) {
            dispatch(change('feedForm', `targets.target_${i}`, targets[`target_${i + 1}`]));
        }

        dispatch(change('feedForm', `targets.target_${length}`, undefined));
    }
};

export const getAdverityAuthorizationsByDatastreamType = (datastreamTypeId) => async (dispatch, getState) => {
    dispatch(storeActions.setFeedFormAdverityAuthorizationsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const clientId = getSelectedClientId(getState());
        const list: Array<ingestionApiDefinitions['AuthorizationsByDatastreamTypeDto']> = await service.api.getAdverityAuthorizationsForIngestionByDatastreamType(clientId, datastreamTypeId);

        const items = list
            .reduce((acc, { authorizations }) => {
                if (authorizations?.length) {
                    return acc.concat(authorizations);
                }

                return acc;
            }, [] as Array<ingestionApiDefinitions['AuthorizationDto']>)
            .sort(({ name: name1 }, { name: name2 }) => (name1! > name2! ? 1 : -1));

        dispatch(storeActions.setFeedFormAdverityAuthorizations(items));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setFeedFormAdverityAuthorizationsLoadStatus(LOAD_STATUS.LOADED));
    }
};
