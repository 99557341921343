import * as t from '../actionTypes';

export const setReportsListLoadStatus = (data) => ({
    type: t.SET_REPORTS_LIST_LOAD_STATUS,
    payload: data
});

export const setReportsListItems = (data) => ({
    type: t.SET_REPORTS_LIST_ITEMS,
    payload: data
});

export const appendReportsListItems = (data) => ({
    type: t.APPEND_REPORTS_LIST_ITEMS,
    payload: data
});

export const setReportsListCount = (data) => ({
    type: t.SET_REPORTS_LIST_COUNT,
    payload: data
});

export const resetReportsList = () => ({
    type: t.RESET_REPORTS_LIST
});

export const setReportsListSortOrder = (data) => ({
    type: t.SET_REPORTS_LIST_SORT_ORDER,
    payload: data
});

export const setReportsListSortColumn = (data) => ({
    type: t.SET_REPORTS_LIST_SORT_COLUMN,
    payload: data
});

export const setReportsListPage = (data) => ({
    type: t.SET_REPORTS_LIST_PAGE,
    payload: data
});

export const setReportsListSearchText = (data) => ({
    type: t.SET_REPORTS_LIST_SEARCH_TEXT,
    payload: data
});

export const setReportsListOperationInProgress = (data) => ({
    type: t.SET_REPORTS_LIST_OPERATION_IN_PROGRESS,
    payload: data
});

export const setReportDetails = (data) => ({
    type: t.SET_REPORT_DETAILS,
    payload: data
});

export const setReportDetailsLoadStatus = (data) => ({
    type: t.SET_REPORT_DETAILS_LOAD_STATUS,
    payload: data
});

export const setReportDetailsTableSortColumn = (data) => ({
    type: t.SET_REPORT_DETAILS_TABLE_SORT_COLUMN,
    payload: data
});

export const setReportDetailsTableSortOrder = (data) => ({
    type: t.SET_REPORT_DETAILS_TABLE_SORT_ORDER,
    payload: data
});

export const setReportDetailsOperationInProgress = (data) => ({
    type: t.SET_REPORT_DETAILS_OPERATION_IN_PROGRESS,
    payload: data
});

export const resetReportDetails = () => ({
    type: t.RESET_REPORT_DETAILS
});

export const setReportFormTemplates = (data) => ({
    type: t.SET_REPORT_FORM_TEMPLATES,
    payload: data
});

export const setReportFormTemplatesLoadStatus = (data) => ({
    type: t.SET_REPORT_FORM_TEMPLATES_LOAD_STATUS,
    payload: data
});

export const setReportFormMarkets = (data) => ({
    type: t.SET_REPORT_FORM_MARKETS,
    payload: data
});

export const setReportFormMarketsLoadStatus = (data) => ({
    type: t.SET_REPORT_FORM_MARKETS_LOAD_STATUS,
    payload: data
});

export const setReportFormTemplate = (data) => ({
    type: t.SET_REPORT_FORM_TEMPLATE,
    payload: data
});

export const setReportFormTemplateLoadStatus = (data) => ({
    type: t.SET_REPORT_FORM_TEMPLATE_LOAD_STATUS,
    payload: data
});

export const setReportFormCollectorCredentials = (data) => ({
    type: t.SET_REPORT_FORM_COLLECTOR_CREDENTIALS,
    payload: data
});

export const setReportFormCollectorCredentialsLoadStatus = (data) => ({
    type: t.SET_REPORT_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS,
    payload: data
});

export const setReportFormOperationInProgress = (data) => ({
    type: t.SET_REPORT_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const addReportPlatform = () => ({
    type: t.ADD_REPORT_PLATFORM
});

export const removeReportPlatform = (data) => ({
    type: t.REMOVE_REPORT_PLATFORM,
    payload: data
});

export const setReportPlatforms = (data) => ({
    type: t.SET_REPORT_PLATFORMS,
    payload: data
});

export const setReportFormStage = (data) => ({
    type: t.SET_REPORT_FORM_STAGE,
    payload: data
});

export const resetReportForm = () => ({
    type: t.RESET_REPORT_FORM
});

export const setReportCollectorCredentialFormCollectors = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS,
    payload: data
});

export const setReportCollectorCredentialFormCollectorsLoadStatus = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS,
    payload: data
});

export const setReportCollectorCredentialFormManifest = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST,
    payload: data
});

export const setReportCollectorCredentialFormManifestLoadStatus = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS,
    payload: data
});

export const setReportCollectorCredentialFormOperationInProgress = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_OPERATION_IN_PROGRESS,
    payload: data
});

export const setReportCollectorCredentialFormDrawerOpen = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DRAWER_OPEN,
    payload: data
});

export const setReportCollectorCredentialFormData = (data) => ({
    type: t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DATA,
    payload: data
});

export const setReportAssets = (data) => ({
    type: t.SET_REPORT_ASSETS,
    payload: data
});

export const setReportAssetsLoadStatus = (data) => ({
    type: t.SET_REPORT_ASSETS_LOAD_STATUS,
    payload: data
});

export const setReportAssetsOperationInProgress = (data) => ({
    type: t.SET_REPORT_ASSETS_OPERATION_IN_PROGRESS,
    payload: data
});

export const setReportAssetsTableSortColumn = (data) => ({
    type: t.SET_REPORT_ASSETS_TABLE_SORT_COLUMN,
    payload: data
});

export const setReportAssetsTableSortOrder = (data) => ({
    type: t.SET_REPORT_ASSETS_TABLE_SORT_ORDER,
    payload: data
});

export const setReportAssetStatus = (data) => ({
    type: t.SET_REPORT_ASSET_STATUS,
    payload: data
});

export const setReportStatus = (data) => ({
    type: t.SET_REPORT_STATUS,
    payload: data
});

export const setReportFormAdverityAuthorizations = (data) => ({
    type: t.SET_REPORT_FORM_ADVERITY_AUTHORIZATIONS,
    payload: data
});
