const MEMBER_API = '/odp-member-api/api/v1';
const INGESTION_API = '/odp-ingestion-api/api/v1';
const TRANSFORMATION_API = '/odp-transformation-api/api/v1';
const DATA_CATALOG_API = '/odp-datacatalog-api/api/v1';
const MANUAL_UPLOAD_API = '/odp-manual-upload-api/api/v1';
const REPORTING_API = '/odp-reporting-api/api/v1';

export const USER_PROFILE = `${MEMBER_API}/member/profile`;
export const CLIENT = `${MEMBER_API}/client`;
export const CLIENT_BY_ID = `${MEMBER_API}/client/{0}`;
export const GET_MEMBER_CLIENTS = `${MEMBER_API}/member/client`;
export const CLIENT_ROLE = `${MEMBER_API}/member/{0}/client/{1}/{2}`;
export const CLIENT_MEMBER = `${MEMBER_API}/client/{0}/member`;
export const CLIENT_MEMBERSHIP = `${MEMBER_API}/member/{0}/client/{1}`;
export const OWNER = `${MEMBER_API}/owner`;
export const OWNER_BY_ID = `${MEMBER_API}/owner/{0}`;
export const GET_MEMBER_OWNERS = `${MEMBER_API}/member/owner`;
export const OWNER_ROLE = `${MEMBER_API}/member/{0}/owner/{1}/{2}`;
export const OWNER_MEMBERSHIP = `${MEMBER_API}/member/{0}/owner/{1}`;
export const OWNER_MEMBER = `${MEMBER_API}/owner/{0}/member`;
export const MEMBERS_SEARCH = `${MEMBER_API}/member/search`;
export const MEMBER = `${MEMBER_API}/member/{0}`;
export const ADD_MEMBER = `${MEMBER_API}/member`;
export const GROUPS_SEARCH = `${MEMBER_API}/group/search`;
export const GROUP = `${MEMBER_API}/group`;
export const GROUP_BY_ID = `${MEMBER_API}/group/{0}`;
export const GROUP_MEMBERSHIP_BY_ID = `${MEMBER_API}/group/{0}/member/{1}`;
export const GROUP_MEMBERSHIP = `${MEMBER_API}/group/{0}/member`;

export const COLLECTOR_CREDENTIALS_SEARCH = `${INGESTION_API}/credential/search`;
export const COLLECTOR_CREDENTIALS_LIST = `${INGESTION_API}/credential`;
export const FEEDS_SEARCH = `${INGESTION_API}/client/{0}/feed/search`;
export const ADD_FEED = `${INGESTION_API}/client/{0}/feed`;
export const FEED = `${INGESTION_API}/client/{0}/feed/{1}`;
export const COLLECTOR_SEARCH = `${INGESTION_API}/collector`;
export const COLLECTOR_CREDENTIAL_MANIFEST = `${INGESTION_API}/collector/{0}/credential-manifest`;
export const ADD_COLLECTOR_CREDENTIAL = `${INGESTION_API}/owner/{0}/credential`;
export const COLLECTOR_CREDENTIAL = `${INGESTION_API}/owner/{0}/credential/{1}`;
export const SOURCES_SEARCH = `${INGESTION_API}/source/search`;
export const SOURCES_LIST = `${INGESTION_API}/source?onlyValid={0}`;
export const CURRENT_SOURCE_VERSION_DETAILS = `${INGESTION_API}/owner/{0}/source/{1}/version?excludingValid={2}`;
export const SOURCE_VERSION = `${INGESTION_API}/owner/{0}/source/{1}/version/{2}`;
export const COLLECTOR_CONFIGURATION_MANIFEST = `${INGESTION_API}/collector/{0}/configuration-manifest`;
export const ADD_SOURCE = `${INGESTION_API}/owner/{0}/source`;
export const SOURCE = `${INGESTION_API}/owner/{0}/source/{1}`;
export const CURRENT_FEED_VERSION_DETAILS = `${INGESTION_API}/client/{0}/feed/{1}/version?excludingValid={2}`;
export const COLLECTION_OFFSET_TEMPLATE_BY_ID = `${INGESTION_API}/collection-offset-template/{0}`;
export const COLLECTION_OFFSET_TEMPLATE = `${INGESTION_API}/collection-offset-template`;
export const FEED_SCHEDULE_PAUSE = `${INGESTION_API}/client/{0}/feed/{1}/pause`;
export const FEED_SCHEDULE_RESTART = `${INGESTION_API}/client/{0}/feed/{1}/unpause`;
export const FEED_ENABLE = `${INGESTION_API}/client/{0}/feed/{1}/enable`;
export const FEED_DISABLE = `${INGESTION_API}/client/{0}/feed/{1}/disable`;
export const RUN_FEED = `${INGESTION_API}/client/{0}/feed/{1}/version/{2}/run`;
export const FEEDS_LIST = `${INGESTION_API}/client/{0}/feed/lite`;
export const ARCHIVE_SOURCE = `${INGESTION_API}/owner/{0}/source/{1}/archive`;
export const FEED_RUNS_LIST = `${INGESTION_API}/client/{0}/feed/runs/list`;
export const FEED_RUNS_RESTART = `${INGESTION_API}/client/{0}/feed/rerun`;
export const PROMOTE_SOURCE = `${INGESTION_API}/owner/{0}/source/{1}/promote`;
export const TARGET_CREDENTIAL_MANIFEST = `${INGESTION_API}/target/type/{0}/authentication-method/{1}/credential-manifest`;
export const TARGET_CREDENTIAL = `${INGESTION_API}/owner/{0}/target-credential`;
export const TARGET_CREDENTIAL_BY_ID = `${INGESTION_API}/owner/{0}/target-credential/{1}`;
export const TARGET_CREDENTIALS_SEARCH = `${INGESTION_API}/target-credential/search`;
export const TARGETS_SEARCH = `${INGESTION_API}/target/search`;
export const TARGET = `${INGESTION_API}/owner/{0}/target`;
export const TARGET_BY_ID = `${INGESTION_API}/owner/{0}/target/{1}`;
export const TARGET_CONFIGURATION_MANIFEST = `${INGESTION_API}/target/type/{0}/configuration-manifest`;
export const TARGETS_LIST = `${INGESTION_API}/target`;
export const TARGET_CREDENTIALS_LIST = `${INGESTION_API}/target-credential`;
export const INGESTION_SUBSCRIPTION = `${INGESTION_API}/subscription`;
export const INGESTION_SUBSCRIPTIONS_BY_REFERENCE_ENTITY_ID = `${INGESTION_API}/subscriptions/{0}?eventTypeGroup={1}`;
export const ADVERITY_AUTHORIZATION_TYPES = `${INGESTION_API}/authorization-types`;
export const ADVERITY_AUTHORIZATION_TYPE_OPTIONS = `${INGESTION_API}/authorization-types/{0}/options`;
export const ADVERITY_AUTHORIZATIONS = `${INGESTION_API}/client/{0}/authorization-types/{1}/authorizations`;
export const ADVERITY_AUTHORIZATION_BY_ID = `${INGESTION_API}/client/{0}/authorization-types/{1}/authorizations/{2}`;
export const AUTHORIZE_ADVERITY_AUTHORIZATION = `${INGESTION_API}/client/{0}/authorization-types/{1}/authorizations/{2}/authorize`;
export const ADVERITY_DATASTREAM_TYPES = `${INGESTION_API}/datastream-types`;
export const INGESTION_ADVERITY_AUTHORIZATIONS_BY_DATASTREAM_TYPE = `${INGESTION_API}/client/{0}/datastream-types/{1}/authorizations`;

export const PIPELINES_SEARCH = `${TRANSFORMATION_API}/transformation-pipeline/search`;
export const TRANSFORMATION_PIPELINE = `${TRANSFORMATION_API}/owner/{0}/transformation-pipeline/{1}`;
export const INSTANCES_SEARCH = `${TRANSFORMATION_API}/client/{0}/transformation-instance/search`;
export const TRANSFORMATION_INSTANCE = `${TRANSFORMATION_API}/client/{0}/transformation-instance/{1}`;
export const RUN_INSTANCE = `${TRANSFORMATION_API}/client/{0}/transformation-instance/{1}/run`;
export const TRANSFORMATION_EXPORT_TARGETS = `${TRANSFORMATION_API}/transformation-export-targets`;
export const PIPELINES_LIST = `${TRANSFORMATION_API}/transformation-pipeline/lite`;
export const ADD_TRANSFORMATION_INSTANCE = `${TRANSFORMATION_API}/client/{0}/transformation-instance`;
export const TRANSFORMATION_INSTANCES_LIST = `${TRANSFORMATION_API}/client/{0}/transformation-instance/lite`;
export const VALIDATE_AP_SQL = `${TRANSFORMATION_API}/validation/ap-sql`;
export const INSTANCES_BY_TRIGGER = `${TRANSFORMATION_API}/client/{0}/transformation-instance/triggered-by`;

export const GET_DATASETS = `${DATA_CATALOG_API}/datasets`;
export const GET_DATASET_METADATA = `${DATA_CATALOG_API}/datasets/{0}`;
export const GET_DATASETS_TABLES = `${DATA_CATALOG_API}/datasets/{0}/tables`;
export const GET_TABLE_METADATA = `${DATA_CATALOG_API}/datasets/{0}/tables/{1}`;
export const DATA_CATALOG_SEARCH = `${DATA_CATALOG_API}/search`;

export const MANUAL_UPLOAD = `${MANUAL_UPLOAD_API}/client/{0}/{1}`;

export const REPORTS_SEARCH = `${REPORTING_API}/client/{0}/report/search`;
export const REPORT_BY_ID = `${REPORTING_API}/client/{0}/report/{1}`;
export const REPORT = `${REPORTING_API}/client/{0}/report`;
export const REPORT_TEMPLATE = `${REPORTING_API}/template`;
export const REPORT_TEMPLATE_BY_ID = `${REPORTING_API}/template/{0}`;
export const REPORT_MARKET = `${REPORTING_API}/market`;
export const REPORT_COLLECTOR_CREDENTIALS_LIST = `${REPORTING_API}/collector-credential`;
export const VALIDATE_REPORT = `${REPORTING_API}/client/{0}/report/validate`;
export const ADD_REPORT_COLLECTOR_CREDENTIAL = `${REPORTING_API}/owner/{0}/collector-credential`;
export const REPORT_ASSETS = `${REPORTING_API}/client/{0}/report/{1}/assets`;
export const RUN_REPORT = `${REPORTING_API}/client/{0}/report/{1}/rerun`;
export const RUN_REPORT_PLATFORM = `${REPORTING_API}/client/{0}/report/{1}/platform/{2}/rerun`;
export const RUN_REPORT_INSTANCE = `${REPORTING_API}/client/{0}/report/{1}/instance/{2}/rerun`;
export const REPORTING_ADVERITY_AUTHORIZATIONS_BY_DATASTREAM_TYPE = `${REPORTING_API}/client/{0}/datastream-types/{1}/authorizations`;
export const REPORTING_SUBSCRIPTION = `${REPORTING_API}/subscription`;
export const REPORTING_SUBSCRIPTIONS_BY_REFERENCE_ENTITY_ID = `${REPORTING_API}/subscriptions/{0}`;
