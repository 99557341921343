import React, { Fragment, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { ingestionApiDefinitions } from 'modules/service/types';
import { REPORTS_ROUTES } from 'modules/reports/constants';
import { PERMISSIONS } from 'modules/common/constants';
import { formatString } from 'modules/common/utils';

import local from './local.module.scss';

interface FeedReportsListProps {
    reports: Array<ingestionApiDefinitions['ReportDto']>;
}

const DEFAULT_ITEMS_COUNT = 3;

const FeedReportsList: FunctionComponent<FeedReportsListProps> = ({ reports }) => {
    const { clients } = useSelector(getUserProfile);
    const clientId = useSelector(getSelectedClientId);

    const client = clients.find(({ id }) => id === clientId);
    const canViewReports = client?.permissions.includes(PERMISSIONS.REPORTS.VIEW);

    const [showAll, setShowAll] = useState(false);
    const reportsToDisplay = (showAll ? reports : reports.slice(0, DEFAULT_ITEMS_COUNT))
        .sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));

    return (
        <Fragment>
            <div className={local.title}>
                <FormattedMessage id={reports.length > 1 ? 'common.usedInReports' : 'common.usedInReport'} />
            </div>
            {
                reportsToDisplay.map(({ id, name }) => (
                    <div key={id} className={local.item}>
                        {
                            canViewReports ?
                                <a href={`#${formatString(REPORTS_ROUTES.ASSETS, clientId, id)}`}>{name}</a> :
                                name
                        }
                    </div>
                ))
            }
            {
                reports.length > DEFAULT_ITEMS_COUNT &&
                <div className='ls-button'>
                    <button className={local.button} onClick={() => { setShowAll(!showAll); }}>
                        <FormattedMessage id={showAll ? 'common.viewLess' : 'common.viewAll'} />
                    </button>
                </div>
            }
        </Fragment>
    );
};

export default FeedReportsList;
