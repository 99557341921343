import { push } from 'redux-first-history';
import { change, reset, SubmissionError } from 'redux-form';

import { intl } from 'modules/i18n';
import service from 'modules/service';
import {
    EVENT_CATEGORY,
    HTTP_CODE,
    TRANSFORMATION_INGEST_MODE,
    LOAD_STATUS,
    PERMISSIONS,
    SCHEDULE_FREQUENCY,
    SORT_ORDER
} from 'modules/common/constants';
import {
    confirm,
    formatString,
    generateCronExpression,
    getErrorText,
    prepareFormErrors,
    prepareFormValuesAsArray,
    prompt,
    showErrorSnackbar
} from 'modules/common/utils';
import { transformationApiDefinitions } from 'modules/service/types';
import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { getRouterPath } from 'modules/common/selectors';
import { addSyncError, removeSubmitError, removeSyncError } from 'modules/form/actions';
import * as storeActions from './storeActions';
import * as selectors from '../selectors';
import {
    INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER,
    INSTANCE_RUN_MODE,
    INSTANCE_STATE,
    INSTANCES_ROUTES,
    METHOD_SWITCH_OPTIONS,
    SAVE_MODE,
    TRIGGER_TYPE
} from '../constants';

export const getInstancesList = () => async (dispatch, getState) => {
    dispatch(storeActions.setInstancesListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();
        const clientId = getSelectedClientId(storeState);
        const payload = selectors.getInstancesListSearchPayload(storeState);

        const list: transformationApiDefinitions['ApiPaginatedCollectionContainerDto«InstanceSearchResponseDto»'] = await service.api.getInstancesSearchList(clientId, payload);

        dispatch(storeActions.appendInstancesListItems(list.objects));
        dispatch(storeActions.setInstancesListCount(list.totalCount));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstancesListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreInstances = () => async (dispatch, getState) => {
    const page = selectors.getInstancesListPage(getState()) + 1;

    dispatch(storeActions.setInstancesListPage(page));
    await dispatch(getInstancesList());

    service.analytics.trackEvent('Load more items', EVENT_CATEGORY.INSTANCES);
};

export const instancesListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const currentSortColumn = selectors.getInstancesListSortColumn(storeState);
    const currentSortOrder = selectors.getInstancesListSortOrder(storeState);

    if (column === currentSortColumn) {
        const order = currentSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setInstancesListSortOrder(order));
    } else {
        dispatch(storeActions.setInstancesListSortColumn(column));

        if (currentSortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setInstancesListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setInstancesListPage(0));
    dispatch(storeActions.setInstancesListItems([]));
    dispatch(storeActions.setInstancesListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('List sort', EVENT_CATEGORY.INSTANCES);
};

export const instancesListSearch = (text) => (dispatch, getState) => {
    const currentSearchText = selectors.getInstancesListSearchText(getState());

    if (text !== currentSearchText) {
        dispatch(storeActions.setInstancesListPage(0));
        dispatch(storeActions.setInstancesListItems([]));
        dispatch(storeActions.setInstancesListSearchText(text));
        dispatch(storeActions.setInstancesListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('List search', EVENT_CATEGORY.INSTANCES);
    }
};

export const goToInstanceDetailsPage = (id) => (dispatch, getState) => {
    const clientId = getSelectedClientId(getState());

    dispatch(push(formatString(INSTANCES_ROUTES.DETAILS, clientId, id)));
};

export const goToAddInstancePage = () => (dispatch) => {
    dispatch(push(INSTANCES_ROUTES.NEW));
};

export const goToEditInstancePage = (clientId, instanceId) => (dispatch) => {
    dispatch(push(formatString(INSTANCES_ROUTES.EDIT, clientId, instanceId)));
};

export const goToInstancesListPage = () => (dispatch) => {
    dispatch(push(INSTANCES_ROUTES.LIST));
};

const fetchInstanceDetails = (clientId, instanceId, setFeedsFromTriggers = false) => async (dispatch) => {
    const details: transformationApiDefinitions['InstanceDetailsResponseDto'] = await service.api.getInstanceDetails(clientId, instanceId);

    if (details.transformationInstanceTriggers) {
        details.transformationInstanceTriggers.sort(
            ({ name: name1 }, { name: name2 }) => (name1! > name2! ? 1 : -1)
        );
    }

    if (setFeedsFromTriggers && details.transformationInstanceTriggers?.length) {
        const feeds = details.transformationInstanceTriggers
            .map(({ upstreamFeedId, name }) => upstreamFeedId ? { id: upstreamFeedId, name } : null)
            .filter(Boolean);

        if (feeds.length) {
            // store feeds from instance triggers in order to be able to display values in feed drop-down, when user
            // doesn't have permission to fetch feeds list
            dispatch(storeActions.setInstanceFormTriggersFeeds(feeds));
            dispatch(storeActions.setInstanceFormTriggersFeedsLoadStatus(LOAD_STATUS.LOADED));
        }
    }

    dispatch(storeActions.setInstanceDetails(details));
};

export const getInstanceDetails = (clientId, instanceId, setFeedsFromTriggers = false) => async (dispatch) => {
    dispatch(storeActions.setInstanceDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        await dispatch(fetchInstanceDetails(clientId, instanceId, setFeedsFromTriggers));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const togglePauseInstanceSchedule = (clientId, instanceId, isScheduleActive) => async (dispatch) => {
    try {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(true));
        dispatch(storeActions.setInstanceDetailsOperationInProgressReporterData({
            reporter: INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER.TOGGLE_PAUSE,
            prevStateData: {
                isScheduleActive
            }
        }));

        await service.api.editInstance(clientId, instanceId, { isScheduleActive: !isScheduleActive });

        service.analytics.trackEvent(isScheduleActive ? 'Pause instance schedule' : 'Restart instance schedule', EVENT_CATEGORY.INSTANCES);

        await dispatch(fetchInstanceDetails(clientId, instanceId));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
        dispatch(storeActions.unsetInstanceDetailsOperationInProgressReporterData());
    }
};

export const toggleEnableInstance = (clientId, instanceId, state) => async (dispatch) => {
    if ([ INSTANCE_STATE.ENABLED, INSTANCE_STATE.DISABLED ].includes(state)) {
        try {
            const isEnabled = state === INSTANCE_STATE.ENABLED;
            const newState = isEnabled ? INSTANCE_STATE.DISABLED : INSTANCE_STATE.ENABLED;

            dispatch(storeActions.setInstanceDetailsOperationInProgress(true));
            dispatch(storeActions.setInstanceDetailsOperationInProgressReporterData({ reporter: INSTANCE_DETAILS_OPERATION_IN_PROGRESS_REPORTER.TOGGLE_STATE }));

            await service.api.editInstance(clientId, instanceId, { state: newState });

            service.analytics.trackEvent(isEnabled ? 'Disable instance' : 'Enable instance', EVENT_CATEGORY.INSTANCES);

            await dispatch(fetchInstanceDetails(clientId, instanceId));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
            dispatch(storeActions.unsetInstanceDetailsOperationInProgressReporterData());
        }
    }
};

export const runInstance = (formValues) => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(true));

        const storeState = getState();
        const clientId = getSelectedClientId(storeState);
        const { supportsExportTargets } = selectors.getInstanceFormFlags(storeState);
        const { id: instanceId } = selectors.getInstanceDetails(storeState);
        const test = selectors.getInstanceRunMode(storeState) === INSTANCE_RUN_MODE.TEST;

        const {
            airflowConfiguration,
            transformationInstanceExportTargets,
            producesFullDataset,
            recreateTable
        } = formValues;

        const exportTargetsConfigurations: any[] = [];

        if (supportsExportTargets && transformationInstanceExportTargets) {
            const exportTargets = Object.keys(transformationInstanceExportTargets);

            if (exportTargets.length) {
                const exportTargetsData = selectors.getExportTargetsData(storeState);

                for (const exportTargetName of exportTargets) {
                    const { configurationTemplate } = exportTargetsData.find(({ name }) => name === exportTargetName);

                    exportTargetsConfigurations.push({
                        exportTargetName,
                        configuration: prepareFormValuesAsArray(configurationTemplate, transformationInstanceExportTargets[exportTargetName])
                    });
                }
            }
        }

        const manifest = selectors.getInstanceFormConfigurationTemplate(storeState);
        const payload = {
            test,
            producesFullDataset,
            recreateTable,
            transformationInstanceExportTargets: supportsExportTargets ? exportTargetsConfigurations : null,
            airflowConfiguration: prepareFormValuesAsArray(manifest, airflowConfiguration)
        };

        await service.api.runInstance(clientId, instanceId, payload);

        service.analytics.trackEvent('Run instance', EVENT_CATEGORY.INSTANCES, test ? 'Test mode' : '');

        dispatch(storeActions.closeAdHocRunDialog());
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
    }
};

const getInstanceCommonPayload = (formValues, storeState) => {
    const {
        description = null,
        pipelineId = null,
        airflowConfiguration,
        exportTargets,
        transformationInstanceExportTargets,
        producesFullDataset,
        cron,
        method,
        airflowConfigurationOverrides,
        ingestMode,
        primaryKeyColumns,
        clusterColumns,
        overwriteByColumns,
        triggersLogicType,
        timePartitioningColumn = null
    } = formValues;

    const { supportsIngestionSettings, supportsExportTargets } = selectors.getInstanceFormFlags(storeState);

    const needSchedule = [ METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER, METHOD_SWITCH_OPTIONS.SCHEDULE ].includes(method);
    const needTriggers = [ METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER, METHOD_SWITCH_OPTIONS.TRIGGER ].includes(method);

    const cronExpression = needSchedule && cron ? generateCronExpression(cron) : '';

    const configurationTemplate = selectors.getInstanceFormConfigurationTemplate(storeState);
    const triggerConfigurationTemplate = selectors.getInstanceFormTriggerConfigurationTemplate(storeState);

    const triggers = selectors.getInstanceFormTriggers(storeState);
    const transformationInstanceTriggers = needTriggers ?
        triggers
            .map(({ id, type, overrideConfiguration }, idx) => {
                if (!id) {
                    return null;
                }

                const key = type === TRIGGER_TYPE.UPSTREAM_INSTANCE ? 'upstreamInstanceId' : 'upstreamFeedId';

                return overrideConfiguration ?
                    {
                        [key]: id,
                        airflowConfigurationOverrides: prepareFormValuesAsArray(triggerConfigurationTemplate, airflowConfigurationOverrides?.[`trigger_${idx}`] || {})
                    } :
                    { [key]: id };
            })
            .filter(Boolean) :
        [];

    const exportTargetsConfigurations: any[] = [];

    if (supportsExportTargets && exportTargets?.length) {
        const exportTargetsData = selectors.getExportTargetsData(storeState);

        for (const exportTargetName of exportTargets) {
            if (transformationInstanceExportTargets.hasOwnProperty(exportTargetName)) {
                const { configurationTemplate: exportTargetConfigurationTemplate } = exportTargetsData.find(({ name }) => name === exportTargetName);

                exportTargetsConfigurations.push({
                    exportTargetName,
                    configuration: prepareFormValuesAsArray(
                        exportTargetConfigurationTemplate,
                        transformationInstanceExportTargets[exportTargetName]
                    )
                });
            }
        }
    }

    return {
        pipelineId,
        description,
        cronExpression,
        transformationInstanceTriggers,
        producesFullDataset,
        instanceTriggerLogicalType: triggersLogicType,
        transformationInstanceExportTargets: supportsExportTargets ? exportTargetsConfigurations : null,
        airflowConfiguration: airflowConfiguration ? prepareFormValuesAsArray(configurationTemplate, airflowConfiguration) : [],
        ingestionSettings: supportsIngestionSettings ? {
            ingestMode,
            timePartitioningColumn,
            primaryKeyColumns: primaryKeyColumns ? primaryKeyColumns.split(',') : [],
            clusterColumns: clusterColumns ? clusterColumns.split(',') : [],
            overwriteByColumns: ingestMode === TRANSFORMATION_INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS && overwriteByColumns ?
                overwriteByColumns.split(',') :
                []
        } : null
    };
};

const validateInstanceFormValues = (getState, formValues, mode) => {
    const storeState = getState();
    const { supportsIngestionSettings } = selectors.getInstanceFormFlags(storeState);

    const isDraft = mode === SAVE_MODE.DRAFT;
    const { ingestMode, primaryKeyColumns, clusterColumns, overwriteByColumns, method, cron: { frequency } } = formValues;

    const requiredFieldsForDraft = [ 'purpose', 'pipelineId' ];
    const requiredFields = [ ...requiredFieldsForDraft ];

    if (supportsIngestionSettings) {
        requiredFields.push('ingestMode');
        requiredFields.push('primaryKeyColumns');

        if (ingestMode === TRANSFORMATION_INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS) {
            requiredFields.push('overwriteByColumns');
        }
    }

    const errors: any = {};

    if (isDraft) {
        requiredFieldsForDraft.forEach((fieldName) => {
            if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
                errors[fieldName] = 'custom.Required';
            }
        });
    } else {
        if ([ METHOD_SWITCH_OPTIONS.SCHEDULE, METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER ].includes(method)) {
            const requiredCronFields: string[] = [];

            if (frequency === SCHEDULE_FREQUENCY.CUSTOM) {
                requiredCronFields.push('expression');
            } else {
                requiredCronFields.push('hour');
                requiredCronFields.push('minute');

                if (frequency === SCHEDULE_FREQUENCY.WEEKLY) {
                    requiredCronFields.push('weekday');
                } else if (frequency === SCHEDULE_FREQUENCY.MONTHLY) {
                    requiredCronFields.push('dayOfMonth');
                }
            }

            const cronErrors = {};
            requiredCronFields.forEach((fieldName) => {
                if (formValues.cron[fieldName] === undefined || formValues.cron[fieldName] === null) {
                    cronErrors[fieldName] = 'custom.Required';
                }
            });

            if (Object.keys(cronErrors).length) {
                errors.cron = cronErrors;
            }
        }

        if ([ METHOD_SWITCH_OPTIONS.TRIGGER, METHOD_SWITCH_OPTIONS.SCHEDULE_AND_TRIGGER ].includes(method)) {
            const triggers = selectors.getInstanceFormTriggers(getState());
            const triggersErrors = {};

            triggers.forEach(({ id, type }, idx) => {
                if (!id || !type) {
                    triggersErrors[`trigger_${idx}`] = 'custom.Required';
                }
            });

            if (Object.keys(triggersErrors).length) {
                errors.transformationInstanceTriggers = triggersErrors;
            }
        }

        requiredFields.forEach((fieldName) => {
            if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
                errors[fieldName] = 'custom.Required';
            }
        });

        if (supportsIngestionSettings) {
            const primaryKeyColumnsArray = primaryKeyColumns ? primaryKeyColumns.split(',') : [];
            if ((new Set(primaryKeyColumnsArray).size !== primaryKeyColumnsArray.length)) {
                errors.primaryKeyColumns = 'custom.DuplicateColumns';
            }

            const clusterColumnsArray = clusterColumns ? clusterColumns.split(',') : [];
            if (clusterColumnsArray.length) {
                if (clusterColumnsArray.length > 4) {
                    errors.clusterColumns = 'custom.ClusterColumnsMaxLengthExceeded';
                } else if ((new Set(clusterColumnsArray).size !== clusterColumnsArray.length)) {
                    errors.clusterColumns = 'custom.DuplicateColumns';
                }
            }

            if (ingestMode === TRANSFORMATION_INGEST_MODE.INSERT_OR_OVERWRITE_BY_COLUMNS) {
                const overwriteByColumnsArray = overwriteByColumns ? overwriteByColumns.split(',') : [];
                if (overwriteByColumnsArray.length > 4) {
                    errors.overwriteByColumns = 'custom.OverwriteByColumnsMaxLengthExceeded';
                } else if ((new Set(overwriteByColumnsArray).size !== overwriteByColumnsArray.length)) {
                    errors.overwriteByColumns = 'custom.DuplicateColumns';
                }
            }
        }
    }

    return Object.keys(errors).length ? errors : undefined;
};

export const prepareInstanceFormErrors = (data) => {
    const map = {
        cronExpression: 'cron.expression'
    };

    data.validationErrors = data.validationErrors.map(({ code, field, description }) => ({
        code,
        description,
        field: map[field] || field
    }));

    return prepareFormErrors(data);
};

export const editInstance = (formValues, mode) => async (dispatch, getState) => {
    const errors = validateInstanceFormValues(getState, formValues, mode);
    if (errors) {
        throw new SubmissionError(errors);
    }

    let purpose;
    const storeState = getState();
    const {
        id: instanceId,
        state: currentState,
        isScheduleActive,
        linkedReports,
        usedByOdpReportingApi
    } = selectors.getInstanceDetails(storeState);

    if (mode === SAVE_MODE.NEW) {
        purpose = await prompt({
            title: intl.formatMessage({ id: 'common.saveAsNew' }),
            message: intl.formatMessage({ id: 'common.purpose' }),
            placeholder: intl.formatMessage({ id: 'instances.purposePlaceholder' }),
            confirmButtonText: intl.formatMessage({ id: 'common.submit' }),
            cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
            cancelButtonClass: 'btn-flat',
            normalize: (value) => value.replace(/\W+/g, '').toUpperCase()
        });

        if (!purpose) {
            return;
        }
    } else {
        const confirmation = usedByOdpReportingApi ?
            await confirm({
                title: intl.formatMessage({ id: 'instances.editInstance' }),
                messages: [ formatString(intl.formatMessage({ id: 'instances.editInstanceConfirmationMessage' }), linkedReports?.length) ],
                cancelButtonClass: 'btn-flat'
            }) :
            true;

        if (!confirmation) {
            return;
        }
    }

    dispatch(storeActions.setInstanceFormOperationInProgress(true));

    try {
        let state;
        let needToAddNew;

        switch (mode) {
            case SAVE_MODE.DRAFT:
                state = INSTANCE_STATE.DRAFT;
                needToAddNew = false;
                break;
            case SAVE_MODE.NEW:
                state = currentState === INSTANCE_STATE.DRAFT ? INSTANCE_STATE.ENABLED : currentState;
                needToAddNew = true;
                break;
            case SAVE_MODE.NORMAL:
                state = currentState === INSTANCE_STATE.DRAFT ? INSTANCE_STATE.ENABLED : currentState;
                needToAddNew = false;
                break;
        }

        const commonPayload = getInstanceCommonPayload(formValues, storeState);
        const payload: any = {
            ...commonPayload,
            isScheduleActive: (state === INSTANCE_STATE.ENABLED) && Boolean(commonPayload.cronExpression && isScheduleActive),
            state
        };

        if (needToAddNew) {
            payload.purpose = purpose;
        }

        const clientId = getSelectedClientId(storeState);

        let instanceIdToView = instanceId;
        if (needToAddNew) {
            const instanceDetails: transformationApiDefinitions['InstanceCreateResponseDto'] = await service.api.addInstance(clientId, payload);

            instanceIdToView = instanceDetails.id;

            service.analytics.trackEvent('Create instance', EVENT_CATEGORY.INSTANCES);
        } else {
            await service.api.editInstance(clientId, instanceId, payload);

            service.analytics.trackEvent('Edit instance', EVENT_CATEGORY.INSTANCES);
        }

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(INSTANCES_ROUTES.EDIT, clientId, instanceId)) {
            dispatch(goToInstanceDetailsPage(instanceIdToView));
        }
    } catch (error) {
        if (error instanceof SubmissionError) {
            throw error;
        } else {
            const { data, status } = error.response;

            if (status === HTTP_CODE.BAD_REQUEST) {
                throw new SubmissionError(prepareInstanceFormErrors(data));
            } else {
                showErrorSnackbar(getErrorText(error));
            }
        }
    } finally {
        dispatch(storeActions.setInstanceFormOperationInProgress(false));
    }
};

export const addInstance = (formValues, mode) => async (dispatch, getState) => {
    const errors = validateInstanceFormValues(getState, formValues, mode);
    if (errors) {
        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setInstanceFormOperationInProgress(true));

    try {
        const storeState = getState();

        const isDraft = mode === SAVE_MODE.DRAFT;
        const state = isDraft ? INSTANCE_STATE.DRAFT : INSTANCE_STATE.ENABLED;
        const commonPayload = getInstanceCommonPayload(formValues, storeState);
        const payload = {
            ...commonPayload,
            purpose: formValues.purpose,
            isScheduleActive: (state === INSTANCE_STATE.ENABLED) && Boolean(commonPayload.cronExpression),
            state
        };

        const clientId = getSelectedClientId(storeState);
        const { id: instanceId }: transformationApiDefinitions['InstanceCreateResponseDto'] = await service.api.addInstance(clientId, payload);

        service.analytics.trackEvent('Create instance', EVENT_CATEGORY.INSTANCES, isDraft ? 'Draft' : '');

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === INSTANCES_ROUTES.NEW) {
            dispatch(goToInstanceDetailsPage(instanceId));
        }
    } catch (error) {
        if (error instanceof SubmissionError) {
            throw error;
        } else {
            const { data, status } = error.response;

            if (status === HTTP_CODE.BAD_REQUEST) {
                throw new SubmissionError(prepareInstanceFormErrors(data));
            } else {
                showErrorSnackbar(getErrorText(error));
            }
        }
    } finally {
        dispatch(storeActions.setInstanceFormOperationInProgress(false));
    }
};

export const loadExportTargetsData = () => async (dispatch) => {
    dispatch(storeActions.setExportTargetsDataLoadStatus(LOAD_STATUS.LOADING));

    try {
        const { objects } = await service.api.getTransformationExportTargets();
        dispatch(storeActions.setExportTargetsData(
            objects.map((exportTarget) => ({ ...exportTarget, configurationTemplate: JSON.parse(exportTarget.configurationTemplate) }))
        ));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setExportTargetsDataLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getInstanceFormData = (clientId = null) => async (dispatch, getState) => {
    const storeState = getState();

    if (!clientId) {
        clientId = getSelectedClientId(storeState);
    }

    dispatch(storeActions.setInstanceFormPipelinesLoadStatus(LOAD_STATUS.LOADING));
    try {
        const { objects } = await service.api.getPipelines();
        dispatch(storeActions.setInstanceFormPipelines(
            objects.map(({ id, purpose, ownerId, airflowDagId }) => ({ id, ownerId, name: `${purpose} (${airflowDagId})` })))
        );
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceFormPipelinesLoadStatus(LOAD_STATUS.LOADED));
    }

    await dispatch(loadExportTargetsData());

    dispatch(storeActions.setInstanceFormTriggersInstancesLoadStatus(LOAD_STATUS.LOADING));
    try {
        const { instances } = await service.api.getInstances(clientId);
        dispatch(storeActions.setInstanceFormTriggersInstances(instances));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceFormTriggersInstancesLoadStatus(LOAD_STATUS.LOADED));
    }

    const { clients } = getUserProfile(storeState);
    const client = clients.find(({ id }) => id === clientId);
    if (client && client.permissions.includes(PERMISSIONS.FEEDS.VIEW)) {
        dispatch(storeActions.setInstanceFormTriggersFeedsLoadStatus(LOAD_STATUS.LOADING));
        try {
            const { objects } = await service.api.getFeeds(clientId);
            dispatch(storeActions.setInstanceFormTriggersFeeds(objects));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setInstanceFormTriggersFeedsLoadStatus(LOAD_STATUS.LOADED));
        }
    }
};

export const getPipelineDetails = (ownerId, pipelineId) => async (dispatch) => {
    dispatch(storeActions.setInstanceFormPipelineDetailsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const {
            airflowConfigurationTemplate,
            supportsIngestionSettings,
            supportsExportTargets
        }: transformationApiDefinitions['PipelineDetailsResponseDto'] = await service.api.getPipelineDetails(ownerId, pipelineId);

        dispatch(storeActions.setInstanceFormConfigurationTemplate(JSON.parse(airflowConfigurationTemplate!)));
        dispatch(storeActions.setInstanceFormFlags({ supportsIngestionSettings, supportsExportTargets }));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceFormPipelineDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const updateTriggersOverrides = () => (dispatch, getState) => {
    const storeState = getState();
    const configurationTemplate = selectors.getInstanceFormTriggerConfigurationTemplate(storeState);

    if (!configurationTemplate) {
        const triggers = selectors.getInstanceFormTriggers(storeState);

        triggers.forEach((trigger, index) => {
            if (trigger.overrideConfiguration) {
                dispatch(storeActions.updateTrigger({ index, data: { ...trigger, overrideConfiguration: false } }));
            }
        });
    }
};

export const archiveInstance = (instanceId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'instances.archiveInstance' }),
        messages: [ intl.formatMessage({ id: 'instances.archiveInstanceConfirmation' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.archive' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        try {
            dispatch(storeActions.setInstanceDetailsOperationInProgress(true));

            const clientId = getSelectedClientId(getState());

            await service.api.editInstance(clientId, instanceId, { state: INSTANCE_STATE.ARCHIVED });

            service.analytics.trackEvent('Archive instance', EVENT_CATEGORY.INSTANCES);

            await dispatch(fetchInstanceDetails(clientId, instanceId));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
        }
    }
};

export const unarchiveInstance = (instanceId) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'instances.restoreInstance' }),
        messages: [ intl.formatMessage({ id: 'instances.restoreInstanceConfirmation' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.restore' }),
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        try {
            dispatch(storeActions.setInstanceDetailsOperationInProgress(true));

            const clientId = getSelectedClientId(getState());

            await service.api.editInstance(clientId, instanceId, { state: INSTANCE_STATE.DISABLED });

            service.analytics.trackEvent('Unarchive instance', EVENT_CATEGORY.INSTANCES);

            await dispatch(fetchInstanceDetails(clientId, instanceId));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
        }
    }
};

export const toggleArchivedInstances = (value) => (dispatch) => {
    dispatch(storeActions.setInstancesListShowArchived(value));

    dispatch(storeActions.setInstancesListPage(0));
    dispatch(storeActions.setInstancesListItems([]));
    dispatch(storeActions.setInstancesListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent(value ? 'Show archived instances' : 'Hide archived instances', EVENT_CATEGORY.INSTANCES);
};

export const removeInstanceTrigger = (index) => (dispatch, getState) => {
    dispatch(storeActions.removeTrigger(index));

    dispatch(removeSubmitError({
        form: 'instanceForm',
        field: 'transformationInstanceTriggers'
    }));

    const storeState = getState();
    const { length } = selectors.getInstanceFormTriggers(storeState);
    const { airflowConfigurationOverrides } = selectors.getInstanceFormValues(storeState);

    if (airflowConfigurationOverrides) {
        // setTimeout is used in order to rewrite default field values which are set after trigger re-render
        setTimeout(() => {
            for (let i = index; i < length; i++) {
                dispatch(change('instanceForm', `airflowConfigurationOverrides.trigger_${i}`, airflowConfigurationOverrides[`trigger_${i + 1}`]));
            }

            dispatch(change('instanceForm', `airflowConfigurationOverrides.trigger_${length}`, undefined));
        }, 0);
    }
};

export const validateTriggerConfigurationApSql = (formName, fieldName, query) => async (dispatch, getState) => {
    try {
        const storeState = getState();
        const formValues = storeState.form[formName]?.values;

        const path = fieldName.split('.');
        const fieldId = path.pop();
        const panelId = path.at(-1);

        const baseConfiguration = { ...formValues.airflowConfiguration[panelId] };
        delete baseConfiguration[fieldId];

        const manifest = selectors.getInstanceFormConfigurationTemplate(storeState);
        const panel = manifest.panels.find(({ id }) => id === panelId);

        if (panel) {
            panel.fields.forEach(({ id, editable }) => {
                if (editable) {
                    delete baseConfiguration[id];
                }
            });
        }

        let temp = formValues;
        for (const pathPart of path) {
            temp = isNaN(pathPart) ? temp[pathPart] : temp[parseInt(pathPart, 10)];
        }

        const overriddenConfiguration = { ...temp };
        delete overriddenConfiguration[fieldId];

        await service.api.validateApSql(query, { ...baseConfiguration, ...overriddenConfiguration });

        // clear previous errors
        dispatch(removeSyncError({
            form: formName,
            field: fieldName
        }));

        dispatch(removeSubmitError({
            form: formName,
            field: fieldName
        }));
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            dispatch(addSyncError({
                form: formName,
                field: fieldName,
                error: data.validationErrors[0].description
            }));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    }
};

export const showInstanceDeleteDialog = (clientId, instanceId) => async (dispatch) => {
    try {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(true));

        const { instances } = await service.api.getInstancesByTriggerId(clientId, { instanceId });

        dispatch(storeActions.setInstanceDeleteDialogDependentInstances(instances));
        dispatch(storeActions.openInstanceDeleteDialog());
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
    }
};

export const deleteInstance = (clientId, instanceId) => async (dispatch, getState) => {
    try {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(true));

        await service.api.deleteInstance(clientId, instanceId);

        service.analytics.trackEvent('Delete instance', EVENT_CATEGORY.INSTANCES);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === formatString(INSTANCES_ROUTES.DETAILS, clientId, instanceId)) {
            dispatch(goToInstancesListPage());
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setInstanceDetailsOperationInProgress(false));
    }
};

export const resetInstanceFormDataAndValues = () => (dispatch) => {
    dispatch(reset('instanceForm'));
    dispatch(storeActions.resetInstanceForm());
};
