import * as t from '../actionTypes';
import { LOAD_STATUS, SORT_ORDER, TEMP_ID_PREFIX } from 'modules/common/constants';
import { REPORT_ASSET_TYPE, REPORT_FORM_FIRST_STAGE, REPORTS_LIST_PAGE_SIZE } from '../constants';

const ACTION_HANDLERS = {
    [t.SET_REPORTS_LIST_ITEMS]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            items: action.payload
        }
    }),
    [t.APPEND_REPORTS_LIST_ITEMS]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            items: state.reportsList.items.concat(action.payload)
        }
    }),
    [t.SET_REPORTS_LIST_PAGE]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            page: action.payload
        }
    }),
    [t.SET_REPORTS_LIST_COUNT]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            totalCount: action.payload
        }
    }),
    [t.SET_REPORTS_LIST_SORT_COLUMN]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            sortBy: action.payload
        }
    }),
    [t.SET_REPORTS_LIST_SORT_ORDER]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            sortOrder: action.payload
        }
    }),
    [t.SET_REPORTS_LIST_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            loadStatus: action.payload
        }
    }),
    [t.SET_REPORTS_LIST_SEARCH_TEXT]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            searchBy: action.payload
        }
    }),
    [t.SET_REPORTS_LIST_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        reportsList: {
            ...state.reportsList,
            reportOperationInProgress: action.payload
        }
    }),
    [t.RESET_REPORTS_LIST]: (state) => ({
        ...state,
        reportsList: initialState.reportsList
    }),
    [t.SET_REPORT_DETAILS]: (state, action) => ({
        ...state,
        reportDetails: {
            ...state.reportDetails,
            data: action.payload
        }
    }),
    [t.SET_REPORT_DETAILS_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportDetails: {
            ...state.reportDetails,
            loadStatus: action.payload
        }
    }),
    [t.SET_REPORT_DETAILS_TABLE_SORT_COLUMN]: (state, action) => ({
        ...state,
        reportDetails: {
            ...state.reportDetails,
            table: {
                ...state.reportDetails.table,
                sortBy: action.payload
            }
        }
    }),
    [t.SET_REPORT_DETAILS_TABLE_SORT_ORDER]: (state, action) => ({
        ...state,
        reportDetails: {
            ...state.reportDetails,
            table: {
                ...state.reportDetails.table,
                sortOrder: action.payload
            }
        }
    }),
    [t.SET_REPORT_DETAILS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        reportDetails: {
            ...state.reportDetails,
            reportOperationInProgress: action.payload
        }
    }),
    [t.RESET_REPORT_DETAILS]: (state) => ({
        ...state,
        reportDetails: initialState.reportDetails
    }),
    [t.SET_REPORT_FORM_COLLECTOR_CREDENTIALS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                list: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_TEMPLATES]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            templates: {
                ...state.reportForm.templates,
                list: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_TEMPLATES_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            templates: {
                ...state.reportForm.templates,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_MARKETS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            markets: {
                ...state.reportForm.markets,
                list: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_MARKETS_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            markets: {
                ...state.reportForm.markets,
                loadStatus: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_TEMPLATE]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            template: {
                ...state.reportForm.template,
                data: action.payload
            }
        }
    }),
    [t.SET_REPORT_FORM_TEMPLATE_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            template: {
                ...state.reportForm.template,
                loadStatus: action.payload
            }
        }
    }),
    [t.ADD_REPORT_PLATFORM]: (state) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            reportPlatforms: [
                ...state.reportForm.reportPlatforms,
                { id: TEMP_ID_PREFIX + Date.now() }
            ]
        }
    }),
    [t.REMOVE_REPORT_PLATFORM]: (state, action) => {
        const reportPlatforms = [ ...state.reportForm.reportPlatforms ];
        reportPlatforms.splice(action.payload, 1);

        return {
            ...state,
            reportForm: {
                ...state.reportForm,
                reportPlatforms
            }
        };
    },
    [t.SET_REPORT_PLATFORMS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            reportPlatforms: action.payload
        }
    }),
    [t.SET_REPORT_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            reportOperationInProgress: action.payload
        }
    }),
    [t.SET_REPORT_FORM_STAGE]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            stage: action.payload
        }
    }),
    [t.RESET_REPORT_FORM]: (state) => ({
        ...state,
        reportForm: initialState.reportForm
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    collectors: {
                        ...state.reportForm.collectorCredentials.form.collectors,
                        list: action.payload
                    }
                }
            }
        }
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    collectors: {
                        ...state.reportForm.collectorCredentials.form.collectors,
                        loadStatus: action.payload
                    }
                }
            }
        }
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    manifest: {
                        ...state.reportForm.collectorCredentials.form.manifest,
                        object: action.payload
                    }
                }
            }
        }
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    manifest: {
                        ...state.reportForm.collectorCredentials.form.manifest,
                        loadStatus: action.payload
                    }
                }
            }
        }
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    credentialOperationInProgress: action.payload
                }
            }
        }
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DRAWER_OPEN]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    isDrawerOpen: action.payload
                }
            }
        }
    }),
    [t.SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DATA]: (state, action) => ({
        ...state,
        reportForm: {
            ...state.reportForm,
            collectorCredentials: {
                ...state.reportForm.collectorCredentials,
                form: {
                    ...state.reportForm.collectorCredentials.form,
                    data: action.payload
                }
            }
        }
    }),
    [t.SET_REPORT_ASSETS]: (state, action) => ({
        ...state,
        reportAssets: {
            ...state.reportAssets,
            data: action.payload
        }
    }),
    [t.SET_REPORT_ASSETS_LOAD_STATUS]: (state, action) => ({
        ...state,
        reportAssets: {
            ...state.reportAssets,
            loadStatus: action.payload
        }
    }),
    [t.SET_REPORT_ASSETS_OPERATION_IN_PROGRESS]: (state, action) => ({
        ...state,
        reportAssets: {
            ...state.reportAssets,
            reportOperationInProgress: action.payload
        }
    }),
    [t.SET_REPORT_ASSETS_TABLE_SORT_COLUMN]: (state, action) => {
        const { assetType, sortBy } = action.payload;
        const key = assetType === REPORT_ASSET_TYPE.PLATFORM ?
            'platformsTable' : 'instancesTable';

        return {
            ...state,
            reportAssets: {
                ...state.reportAssets,
                [key]: {
                    ...state.reportAssets[key],
                    sortBy
                }
            }
        };
    },
    [t.SET_REPORT_ASSETS_TABLE_SORT_ORDER]: (state, action) => {
        const { assetType, sortOrder } = action.payload;
        const key = assetType === REPORT_ASSET_TYPE.PLATFORM ?
            'platformsTable' : 'instancesTable';

        return {
            ...state,
            reportAssets: {
                ...state.reportAssets,
                [key]: {
                    ...state.reportAssets[key],
                    sortOrder
                }
            }
        };
    },
    [t.SET_REPORT_ASSET_STATUS]: (state, action) => {
        const { assetType, assetId, status } = action.payload;
        const key = assetType === REPORT_ASSET_TYPE.PLATFORM ? 'platforms' : 'instances';
        const assets = state.reportAssets.data[key];
        const index = assets?.findIndex(({ id }) => id === assetId);

        return (index === -1) || (index === undefined) ?
            state :
            {
                ...state,
                reportAssets: {
                    ...state.reportAssets,
                    data: {
                        ...state.reportAssets.data,
                        [key]: [
                            ...assets.slice(0, index),
                            {
                                ...assets[index],
                                executionStatus: status
                            },
                            ...assets.slice(index + 1)
                        ]
                    }
                }
            };
    },
    [t.SET_REPORT_STATUS]: (state, action) => {
        const { reportId, status } = action.payload;
        const reports = state.reportsList.items;
        const index = reports.findIndex(({ id }) => id === reportId);

        return index === -1 ?
            state :
            {
                ...state,
                reportsList: {
                    ...state.reportsList,
                    items: [
                        ...reports.slice(0, index),
                        {
                            ...reports[index],
                            executionStatus: status
                        },
                        ...reports.slice(index + 1)
                    ]
                }
            };
    },
    [t.SET_REPORT_FORM_ADVERITY_AUTHORIZATIONS]: (state, action) => {
        const { datastreamTypeId, items } = action.payload;

        return {
            ...state,
            reportForm: {
                ...state.reportForm,
                adverityAuthorizations: {
                    ...state.reportForm.adverityAuthorizations,
                    [datastreamTypeId]: items
                }
            }
        };
    }
};

export const initialState = {
    reportsList: {
        items: [],
        totalCount: 0,
        page: 0,
        pageSize: REPORTS_LIST_PAGE_SIZE,
        sortBy: 'name',
        sortOrder: SORT_ORDER.ASC,
        searchBy: '',
        loadStatus: LOAD_STATUS.REQUIRED,
        reportOperationInProgress: false
    },
    reportDetails: {
        table: {
            sortBy: 'platformName',
            sortOrder: SORT_ORDER.ASC
        },
        data: {},
        loadStatus: LOAD_STATUS.REQUIRED,
        reportOperationInProgress: false
    },
    reportAssets: {
        data: {},
        platformsTable: {
            sortBy: 'name',
            sortOrder: SORT_ORDER.ASC
        },
        instancesTable: {
            sortBy: 'name',
            sortOrder: SORT_ORDER.ASC
        },
        loadStatus: LOAD_STATUS.REQUIRED,
        reportOperationInProgress: false
    },
    reportForm: {
        templates: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        markets: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED
        },
        collectorCredentials: {
            list: [],
            loadStatus: LOAD_STATUS.REQUIRED,
            form: {
                isDrawerOpen: false,
                data: {},
                collectors: {
                    list: [],
                    loadStatus: LOAD_STATUS.REQUIRED
                },
                manifest: {
                    object: {},
                    loadStatus: LOAD_STATUS.REQUIRED
                },
                credentialOperationInProgress: false
            }
        },
        template: {
            data: {},
            loadStatus: LOAD_STATUS.REQUIRED
        },
        adverityAuthorizations: {},
        reportPlatforms: [],
        stage: REPORT_FORM_FIRST_STAGE,
        reportOperationInProgress: false
    }
};

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
};
