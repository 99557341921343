import { push } from 'redux-first-history';
import { change, SubmissionError, updateSyncErrors } from 'redux-form';

import service from 'modules/service';
import { ingestionApiDefinitions, reportingApiDefinitions } from 'modules/service/types';
import { intl } from 'modules/i18n';
import { removeSyncError } from 'modules/form/actions';
import {
    alert,
    showErrorSnackbar,
    getErrorText,
    formatString,
    prepareFormErrors,
    confirm,
    normalizeManifest,
    prepareFormValues,
    prefixManifestErrors
} from 'modules/common/utils';
import { EVENT_CATEGORY, EVENT_TYPE, HTTP_CODE, LOAD_STATUS, SORT_ORDER } from 'modules/common/constants';
import { getRouterPath } from 'modules/common/selectors';
import { getSelectedClientId } from 'modules/auth/selectors';
import { FEED_RERUN_STATUS } from 'modules/feeds/constants';
import { INSTANCE_RERUN_STATUS } from 'modules/transformationInstances/constants';
import {
    CUSTOM_VALUE,
    REPORT_ASSET_TYPE,
    REPORT_EXECUTION_STATUS,
    REPORT_FORM_SECOND_STAGE,
    REPORT_FORM_THIRD_STAGE,
    REPORT_RERUN_STATUS,
    REPORTS_ROUTES
} from '../constants';
import * as selectors from '../selectors';
import * as storeActions from './storeActions';

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

export const getReportsList = () => async (dispatch, getState) => {
    dispatch(storeActions.setReportsListLoadStatus(LOAD_STATUS.LOADING));

    try {
        const storeState = getState();
        const clientId = getSelectedClientId(storeState);
        const payload = selectors.getReportsListSearchPayload(storeState);

        const list: reportingApiDefinitions['ApiPaginatedCollectionContainerDto'] = await service.api.getReportsList(clientId, payload);

        if (clientId === getSelectedClientId(getState())) { // don't set list data if client was changed during the API call
            dispatch(storeActions.appendReportsListItems(list.objects));
            dispatch(storeActions.setReportsListCount(list.totalCount));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportsListLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadMoreReports = () => async (dispatch, getState) => {
    const page = selectors.getReportsListState(getState()).page + 1;

    dispatch(storeActions.setReportsListPage(page));
    await dispatch(getReportsList());

    service.analytics.trackEvent('Load more items', EVENT_CATEGORY.REPORTS);
};

export const reportsListSort = (column) => (dispatch, getState) => {
    const storeState = getState();
    const { sortBy, sortOrder } = selectors.getReportsListState(storeState);

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setReportsListSortOrder(order));
    } else {
        dispatch(storeActions.setReportsListSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setReportsListSortOrder(SORT_ORDER.ASC));
        }
    }

    dispatch(storeActions.setReportsListPage(0));
    dispatch(storeActions.setReportsListItems([]));
    dispatch(storeActions.setReportsListLoadStatus(LOAD_STATUS.REQUIRED));

    service.analytics.trackEvent('List sort', EVENT_CATEGORY.REPORTS);
};

export const reportsListSearch = (text) => (dispatch, getState) => {
    const { searchBy } = selectors.getReportsListState(getState());

    if (text !== searchBy) {
        dispatch(storeActions.setReportsListPage(0));
        dispatch(storeActions.setReportsListItems([]));
        dispatch(storeActions.setReportsListSearchText(text));
        dispatch(storeActions.setReportsListLoadStatus(LOAD_STATUS.REQUIRED));

        service.analytics.trackEvent('List search', EVENT_CATEGORY.REPORTS);
    }
};

export const goToAddReportPage = () => (dispatch) => {
    dispatch(push(REPORTS_ROUTES.NEW));
};

export const goToReportsListPage = () => (dispatch) => {
    dispatch(push(REPORTS_ROUTES.LIST));
};

export const loadReportDetails = (reportId) => async (dispatch, getState) => {
    dispatch(storeActions.setReportDetailsLoadStatus(LOAD_STATUS.LOADING));
    dispatch(storeActions.setReportDetails({}));

    try {
        const clientId = getSelectedClientId(getState());
        const details = await service.api.getReportDetails(clientId, reportId);

        dispatch(storeActions.setReportDetails(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportDetailsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const reportPlatformsListSort = (column) => (dispatch, getState) => {
    const { sortBy, sortOrder } = selectors.getReportTableState(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setReportDetailsTableSortOrder(order));
    } else {
        dispatch(storeActions.setReportDetailsTableSortColumn(column));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setReportDetailsTableSortOrder(SORT_ORDER.ASC));
        }
    }
};

export const goToEditReportPage = (reportId) => (dispatch, getState) => {
    const clientId = getSelectedClientId(getState());

    dispatch(push(formatString(REPORTS_ROUTES.EDIT, clientId, reportId)));
};

export const loadReportFormData = () => async (dispatch) => {
    try {
        dispatch(storeActions.setReportFormTemplatesLoadStatus(LOAD_STATUS.LOADING));
        dispatch(storeActions.setReportFormMarketsLoadStatus(LOAD_STATUS.LOADING));
        dispatch(storeActions.setReportFormCollectorCredentialsLoadStatus(LOAD_STATUS.LOADING));

        const [templates, markets, collectorCredentials] = await Promise.all([
            service.api.getReportTemplates(),
            service.api.getReportMarkets(),
            service.api.getReportCollectorCredentialsList()
        ]);

        dispatch(storeActions.setReportFormTemplates(templates.objects));
        dispatch(storeActions.setReportFormMarkets(markets.objects));
        dispatch(storeActions.setReportFormCollectorCredentials(collectorCredentials.objects));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportFormTemplatesLoadStatus(LOAD_STATUS.LOADED));
        dispatch(storeActions.setReportFormMarketsLoadStatus(LOAD_STATUS.LOADED));
        dispatch(storeActions.setReportFormCollectorCredentialsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const loadReportDetailsForEditing = (reportId) => async (dispatch, getState) => {
    await dispatch(loadReportDetails(reportId));
    const { platforms } = selectors.getReportDetails(getState());

    if (platforms?.length) {
        dispatch(storeActions.setReportPlatforms(platforms.map((platform, idx) => ({ id: idx }))));
    }
};

export const loadTemplate = (templateId) => async (dispatch) => {
    try {
        dispatch(storeActions.setReportFormTemplateLoadStatus(LOAD_STATUS.LOADING));

        const details = await service.api.getReportTemplateDetails(templateId);

        dispatch(storeActions.setReportFormTemplate(details));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportFormTemplateLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const removePlatform = (index) => (dispatch, getState) => {
    dispatch(storeActions.removeReportPlatform(index));
    dispatch(removeSyncError({
        form: 'reportForm',
        field: 'platforms'
    }));

    const storeState = getState();
    const { length } = selectors.getReportFormSelectedPlatforms(storeState);
    const { platforms } = selectors.getReportFormValues(storeState);

    if (platforms) {
        for (let i = index; i < length; i++) {
            dispatch(change('reportForm', `platforms.platform_${i}`, platforms[`platform_${i + 1}`]));
        }

        dispatch(change('reportForm', `platforms.platform_${length}`, undefined));
    }
};

export const validateReportFormSecondStage = (isEditMode) => async (dispatch, getState) => {
    const storeState = getState();
    const formValues = selectors.getReportFormValues(storeState);

    const errors: any = {};
    const requiredFields = [ 'name', 'templateId' ];

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    if (!errors.templateId) {
        const platforms = selectors.getReportFormSelectedPlatforms(storeState);

        if (!platforms.length) {
            errors.platforms = 'custom.PlatformRequired';
        } else {
            const platformsErrors = {};

            platforms.forEach((platform, idx) => {
                const platformId = `platform_${idx}`;
                const platformErrors: any = {};
                const { platforms: formPlatforms } = formValues;

                if (!formPlatforms || !formPlatforms[platformId]) {
                    platformErrors.platformId = 'custom.Required';
                    platformErrors.marketId = 'custom.Required';
                } else {
                    if (!formPlatforms[platformId].platformId) {
                        platformErrors.platformId = 'custom.Required';
                    }
                    if (!formPlatforms[platformId].marketId) {
                        platformErrors.marketId = 'custom.Required';
                    }
                }

                if (Object.keys(platformErrors).length) {
                    platformsErrors[platformId] = platformErrors;
                }
            });

            if (Object.keys(platformsErrors).length) {
                errors.platforms = platformsErrors;
            }
        }
    }

    if (Object.keys(errors).length) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        dispatch(updateSyncErrors('reportForm', errors, ''));
    } else {
        if (isEditMode) {
            dispatch(storeActions.setReportFormStage(REPORT_FORM_THIRD_STAGE));
        } else {
            try {
                dispatch(storeActions.setReportFormOperationInProgress(true));

                const clientId = getSelectedClientId(storeState);
                const { name } = formValues;

                await service.api.validateReport(clientId, { name });

                dispatch(storeActions.setReportFormStage(REPORT_FORM_THIRD_STAGE));
            } catch (error) {
                const { data, status } = error.response;

                if (status === HTTP_CODE.BAD_REQUEST) {
                    showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

                    dispatch(updateSyncErrors('reportForm', prepareFormErrors(data), ''));
                } else {
                    showErrorSnackbar(getErrorText(error));
                }
            } finally {
                dispatch(storeActions.setReportFormOperationInProgress(false));
            }
        }
    }
};

const validateReportFormThirdStage = ({ platforms: formPlatforms }, storeState) => {
    const errors: any = {};
    const { platforms } = selectors.getReportFormTemplate(storeState);
    const platformsErrors = {};

    Object.entries<{ [key: string]: any }>(formPlatforms).forEach(([platformPrefix, { platformId: currentPlatformId }]) => {
        const { collectors, adverityDatastreamTypeId } = platforms.find(({ platformId }) => platformId === currentPlatformId);
        const platformErrors: any = {};

        collectors.forEach(({ collectorId, vieCollectorId }) => {
            const isAuthorizationRequired = Boolean(adverityDatastreamTypeId && (vieCollectorId === REACT_APP_ADVERITY_COLLECTOR_ID));
            const platformCollectors = formPlatforms[platformPrefix].collectors;

            if (!platformCollectors || !platformCollectors[collectorId]) {
                if (!platformErrors.collectors) {
                    platformErrors.collectors = {};
                }

                platformErrors.collectors[collectorId] = { credentialId: 'custom.Required' };

                if (isAuthorizationRequired) {
                    platformErrors.collectors[collectorId].adverityAuthorization = 'custom.Required';
                }
            } else if (!platformCollectors[collectorId].credentialId || (isAuthorizationRequired && !platformCollectors[collectorId].adverityAuthorization)) {
                if (!platformErrors.collectors) {
                    platformErrors.collectors = {};
                }

                platformErrors.collectors[collectorId] = {};

                if (!platformCollectors[collectorId].credentialId) {
                    platformErrors.collectors[collectorId].credentialId = 'custom.Required';
                }

                if (isAuthorizationRequired && !platformCollectors[collectorId].adverityAuthorization) {
                    platformErrors.collectors[collectorId].adverityAuthorization = 'custom.Required';
                }
            }
        });

        if (Object.keys(platformErrors).length) {
            platformsErrors[platformPrefix] = platformErrors;
        }
    });

    if (Object.keys(platformsErrors).length) {
        errors.platforms = platformsErrors;
    }

    return Object.keys(errors).length ? errors : undefined;
};

const parseAdverityAuthorization = (adverityAuthorization) => {
    let adverityAuthorizationId: number | null = null;
    let adverityAuthorizationName: string | null = null;

    if (adverityAuthorization) {
        const strings = adverityAuthorization.split('.');
        adverityAuthorizationId = parseInt(strings.shift(), 10);
        adverityAuthorizationName = strings.join('.');
    }

    return { adverityAuthorizationId, adverityAuthorizationName };
};

export const addReport = (formValues) => async (dispatch, getState) => {
    const storeState = getState();
    const errors = validateReportFormThirdStage(formValues, storeState);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    try {
        dispatch(storeActions.setReportFormOperationInProgress(true));

        const clientId = getSelectedClientId(storeState);
        const { name, templateId, platforms } = formValues;
        const payload = {
            name,
            templateId,
            platforms: Object.values<{ [key: string]: any }>(platforms).map(({ platformId, marketId, collectors }) => ({
                platformId,
                marketId,
                collectors: Object.entries<{ [key: string]: any }>(collectors).map(([collectorId, { credentialId, adverityAuthorization }]) => {
                    const { adverityAuthorizationId, adverityAuthorizationName } = parseAdverityAuthorization(adverityAuthorization);

                    return {
                        collectorId,
                        adverityAuthorizationId,
                        adverityAuthorizationName,
                        odpIngestionApiCredentialId: credentialId
                    }
                })
            }))
        };

        const { id } = await service.api.addReport(clientId, payload);

        try {
            await service.api.addReportingSubscription({ eventType: EVENT_TYPE.REPORT_FAILED, referenceEntityId: id });
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        }

        service.analytics.trackEvent('Create report', EVENT_CATEGORY.REPORTS);

        const path = getRouterPath(getState());

        // prevent changing location if user is not on this page anymore
        if (path === REPORTS_ROUTES.NEW) {
            dispatch(goToReportsListPage());
        }
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            const validationErrors: any = prepareFormErrors(data);
            // if validation errors object contains any field from form second stage, redirect user to it
            if (validationErrors.name) {
                dispatch(storeActions.setReportFormStage(REPORT_FORM_SECOND_STAGE));
            }

            throw new SubmissionError(validationErrors);
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setReportFormOperationInProgress(false));
    }
};

export const editReport = (reportId, formValues) => async (dispatch, getState) => {
    const storeState = getState();
    const errors = validateReportFormThirdStage(formValues, storeState);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    const { platforms } = formValues;
    const platformsArray = Object.values<{ [key: string]: any }>(platforms);

    const isCustomValueChanged = platformsArray.some(({ marketId, scheduleCustomChanges, collectors }) =>
        (scheduleCustomChanges && marketId !== CUSTOM_VALUE) ||
        Object.values<{ [key: string]: any }>(collectors).some(({ credentialId, customChanges }) => (customChanges && credentialId !== CUSTOM_VALUE)));

    const confirmation = isCustomValueChanged ?
        await confirm({
            title: intl.formatMessage({ id: 'reports.editReport' }),
            messages: [ intl.formatMessage({ id: 'reports.editReportConfirmationMessage' }) ],
            confirmButtonText: intl.formatMessage({ id: 'common.submit' }),
            cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
            cancelButtonClass: 'btn-flat'
        }) : true;

    if (confirmation) {
        try {
            dispatch(storeActions.setReportFormOperationInProgress(true));

            const clientId = getSelectedClientId(storeState);
            const details = selectors.getReportDetails(storeState);
            const payload = {
                platforms: platformsArray.map(({ platformId, marketId, collectors }) => {
                    const savedPlatform = details.platforms.find((platform) => platform.platformId === platformId);

                    const needToUpdateMarket = marketId !== CUSTOM_VALUE;
                    const collectorsToUpdate = Object.entries<{ [key: string]: any }>(collectors)
                        .map(([collectorId, { credentialId, adverityAuthorization }]) => {
                            const { adverityAuthorizationId, adverityAuthorizationName } = parseAdverityAuthorization(adverityAuthorization);

                            let skipCollector = false;
                            if (savedPlatform) {
                                const savedCollector = savedPlatform.collectors.find((collector) => collector.collectorId === collectorId);

                                if (savedCollector && (credentialId === savedCollector.odpIngestionApiCredentialId || credentialId === CUSTOM_VALUE) &&
                                    adverityAuthorizationId === savedCollector.adverityAuthorizationId) {
                                    skipCollector = true;
                                }
                            }

                            return skipCollector ? null : {
                                collectorId,
                                adverityAuthorizationId,
                                adverityAuthorizationName,
                                odpIngestionApiCredentialId: credentialId !== CUSTOM_VALUE ? credentialId : null
                            };
                        }).filter(Boolean);

                    const platformToUpdate: { [key: string]: any } = { platformId };
                    if (needToUpdateMarket) {
                        platformToUpdate.marketId = marketId;
                    }
                    if (collectorsToUpdate.length) {
                        platformToUpdate.collectors = collectorsToUpdate;
                    }

                    return platformToUpdate;
                })
            };

            await service.api.editReport(clientId, reportId, payload);

            service.analytics.trackEvent('Edit report', EVENT_CATEGORY.REPORTS);

            const path = getRouterPath(getState());

            // prevent changing location if user is not on this page anymore
            if (path === formatString(REPORTS_ROUTES.EDIT, clientId, reportId)) {
                dispatch(goToReportsListPage());
            }
        } catch (error) {
            const { data, status } = error.response;

            if (status === HTTP_CODE.BAD_REQUEST) {
                showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

                const validationErrors: any = prepareFormErrors(data);
                // if validation errors object contains any field from form second stage, redirect user to it
                if (validationErrors.name) {
                    dispatch(storeActions.setReportFormStage(REPORT_FORM_SECOND_STAGE));
                }

                throw new SubmissionError(validationErrors);
            } else {
                showErrorSnackbar(getErrorText(error));
            }
        } finally {
            dispatch(storeActions.setReportFormOperationInProgress(false));
        }
    }
};

export const deleteReport = (reportId, setDrawerOpen) => async (dispatch, getState) => {
    const confirmation = await confirm({
        title: intl.formatMessage({ id: 'reports.deleteReport' }),
        messages: [ intl.formatMessage({ id: 'reports.deleteReportConfirmationMessage' }) ],
        confirmButtonText: intl.formatMessage({ id: 'common.delete' }),
        confirmButtonClass: 'btn-negative',
        cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
        cancelButtonClass: 'btn-flat'
    });

    if (confirmation) {
        dispatch(storeActions.setReportDetailsOperationInProgress(true));

        try {
            const clientId = getSelectedClientId(getState());
            await service.api.deleteReport(clientId, reportId);

            service.analytics.trackEvent('Delete report', EVENT_CATEGORY.REPORTS);

            setDrawerOpen(false);

            dispatch(storeActions.setReportsListPage(0));
            dispatch(storeActions.setReportsListItems([]));
            dispatch(storeActions.setReportsListLoadStatus(LOAD_STATUS.REQUIRED));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setReportDetailsOperationInProgress(false));
        }
    }
};

export const getCollectorsList = () => async (dispatch) => {
    dispatch(storeActions.setReportCollectorCredentialFormCollectorsLoadStatus(LOAD_STATUS.LOADING));

    try {
        const response: ingestionApiDefinitions['ApiCollectionContainerDtoCollectorSummaryResponseDto'] = await service.api.getCollectorsList();
        dispatch(storeActions.setReportCollectorCredentialFormCollectors(
            response.objects?.sort(({ description: name1 }, { description: name2 }) => (name1 > name2 ? 1 : -1)))
        );
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportCollectorCredentialFormCollectorsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const getCollectorCredentialManifest = (collectorId) => async (dispatch) => {
    dispatch(storeActions.setReportCollectorCredentialFormManifestLoadStatus(LOAD_STATUS.LOADING));

    try {
        const manifest = await service.api.getCollectorCredentialManifest(collectorId);
        dispatch(storeActions.setReportCollectorCredentialFormManifest(normalizeManifest(manifest)));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportCollectorCredentialFormManifestLoadStatus(LOAD_STATUS.LOADED));
    }
};

const validateCollectorCredentialFormValues = (formValues) => {
    const requiredFields = [ 'name', 'ownerId', 'collectorId' ];
    const errors = {};

    requiredFields.forEach((fieldName) => {
        if (formValues[fieldName] === undefined || formValues[fieldName] === null) {
            errors[fieldName] = 'custom.Required';
        }
    });

    return Object.keys(errors).length ? errors : undefined;
};

export const addCollectorCredential = (formValues) => async (dispatch, getState) => {
    const errors = validateCollectorCredentialFormValues(formValues);
    if (errors) {
        showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

        throw new SubmissionError(errors);
    }

    dispatch(storeActions.setReportCollectorCredentialFormOperationInProgress(true));

    try {
        const {
            manifest: { object: manifest },
            data: {
                collectorId,
                vieCollectorId,
                platformPrefix
            }
        } = selectors.getReportCollectorCredentialForm(getState());
        const { name, ownerId, collectorId: formValuesVieCollectorId, ...collectorConfigurations } = formValues;
        const credentialContext = collectorConfigurations[formValuesVieCollectorId];

        const { id }: reportingApiDefinitions['CredentialDetailResponseDto'] = await service.api.addReportCollectorCredential(
            ownerId,
            {
                name,
                collectorId: formValuesVieCollectorId,
                credentialContext: prepareFormValues(manifest, credentialContext)
            }
        );

        service.analytics.trackEvent('Create collector credential', EVENT_CATEGORY.REPORTS);

        try {
            dispatch(storeActions.setReportFormCollectorCredentialsLoadStatus(LOAD_STATUS.LOADING));
            const { objects } = await service.api.getReportCollectorCredentialsList();
            dispatch(storeActions.setReportFormCollectorCredentials(objects));
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setReportFormCollectorCredentialsLoadStatus(LOAD_STATUS.LOADED));
        }

        if (vieCollectorId === formValuesVieCollectorId) {
            dispatch(change(
                'reportForm',
                `platforms.${platformPrefix}.collectors.${collectorId}.credentialId`,
                id
            ));
        }

        dispatch(storeActions.setReportCollectorCredentialFormDrawerOpen(false));
    } catch (error) {
        const { data, status } = error.response;

        if (status === HTTP_CODE.BAD_REQUEST) {
            data.validationErrors = prefixManifestErrors(data.validationErrors, formValues.collectorId);

            showErrorSnackbar(intl.formatMessage({ id: 'common.validationFailedMessage' }));

            throw new SubmissionError(prepareFormErrors(data));
        } else {
            showErrorSnackbar(getErrorText(error));
        }
    } finally {
        dispatch(storeActions.setReportCollectorCredentialFormOperationInProgress(false));
    }
};

export const goToReportAssetsPage = (reportId) => (dispatch, getState) => {
    const clientId = getSelectedClientId(getState());

    dispatch(push(formatString(REPORTS_ROUTES.ASSETS, clientId, reportId)));
};

export const rerunReportFromListPage = (reportId) => async (dispatch, getState) => {
    dispatch(storeActions.setReportsListOperationInProgress(true));

    try {
        const clientId = getSelectedClientId(getState());
        const { status }: reportingApiDefinitions['ReportRerunResponseDto'] = await service.api.rerunReport(clientId, reportId);

        if (status === REPORT_RERUN_STATUS.FAILED) {
            alert({
                title: intl.formatMessage({ id: 'reports.rerunReport' }),
                content: intl.formatMessage({ id: 'reports.rerunReportFailAlertMessage' })
            });
        } else {
            if (status === REPORT_RERUN_STATUS.PARTIALLY_FAILED) {
                alert({
                    title: intl.formatMessage({ id: 'reports.rerunReport' }),
                    content: intl.formatMessage({ id: 'reports.rerunReportPartialFailAlertMessage' })
                });
            }

            dispatch(storeActions.setReportStatus({
                reportId,
                status: REPORT_EXECUTION_STATUS.RUNNING
            }));
        }
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportsListOperationInProgress(false));
    }
};

export const rerunReportFromAssetsPage = (reportId, showConfirmation) => async (dispatch, getState) => {
    const confirmation = showConfirmation ?
        await confirm({
            title: intl.formatMessage({ id: 'reports.rerunReport' }),
            messages: [ intl.formatMessage({ id: 'reports.rerunReportConfirmationMessage' }) ],
            confirmButtonText: intl.formatMessage({ id: 'reports.rerunReport' }),
            cancelButtonText: intl.formatMessage({ id: 'common.cancel' }),
            cancelButtonClass: 'btn-flat'
        }) :
        true;

    if (confirmation) {
        dispatch(storeActions.setReportAssetsOperationInProgress(true));

        try {
            const clientId = getSelectedClientId(getState());
            const { status }: reportingApiDefinitions['ReportRerunResponseDto'] = await service.api.rerunReport(clientId, reportId);

            if (status === REPORT_RERUN_STATUS.FAILED) {
                alert({
                    title: intl.formatMessage({ id: 'reports.rerunReport' }),
                    content: intl.formatMessage({ id: 'reports.rerunReportFailAlertMessage' })
                });
            } else {
                if (status === REPORT_RERUN_STATUS.PARTIALLY_FAILED) {
                    alert({
                        title: intl.formatMessage({ id: 'reports.rerunReport' }),
                        content: intl.formatMessage({ id: 'reports.rerunReportPartialFailAlertMessage' })
                    });
                }

                dispatch(storeActions.setReportAssets({}));
                dispatch(storeActions.setReportAssetsLoadStatus(LOAD_STATUS.REQUIRED));
            }
        } catch (err) {
            showErrorSnackbar(getErrorText(err));
        } finally {
            dispatch(storeActions.setReportAssetsOperationInProgress(false));
        }
    }
};

const rerunReportPlatform = (clientId, reportId, platformId) => async (dispatch) => {
    const { restarts }: reportingApiDefinitions['ReportPlatformRerunResponseDto'] = await service.api.rerunReportPlatform(clientId, reportId, platformId);
    const failedRestarts = restarts?.filter(({ status }) => status === FEED_RERUN_STATUS.FAILED);

    // show alert in case if there are any failed restarts
    if (failedRestarts?.length) {
        alert({
            title: intl.formatMessage({ id: 'reports.rerunPlatform' }),
            content: intl.formatMessage({ id: 'reports.rerunPlatformAlertMessage' })
        });
    }

    // update platform status only in case if at least one feed was restarted successfully
    if (restarts?.length !== failedRestarts?.length) {
        dispatch(storeActions.setReportAssetStatus({
            assetType: REPORT_ASSET_TYPE.PLATFORM,
            assetId: platformId,
            status: REPORT_EXECUTION_STATUS.RUNNING
        }));
    }
};

const rerunReportInstance = (clientId, reportId, instanceId) => async (dispatch) => {
    const { instanceRerun }: reportingApiDefinitions['InstanceRerunResponseDto'] = await service.api.rerunReportInstance(clientId, reportId, instanceId);

    // show alert in case if system couldn't restart the instance
    if (!instanceRerun || (instanceRerun.status !== INSTANCE_RERUN_STATUS.RESTARTED)) {
        alert({
            title: intl.formatMessage({ id: 'reports.rerunInstance' }),
            content: intl.formatMessage({ id: 'reports.rerunInstanceAlertMessage' })
        });
    } else {
        dispatch(storeActions.setReportAssetStatus({
            assetType: REPORT_ASSET_TYPE.INSTANCE,
            assetId: instanceId,
            status: REPORT_EXECUTION_STATUS.RUNNING
        }));
    }
};

export const rerunReportAsset = (assetType, reportId, assetId) => async (dispatch, getState) => {
    dispatch(storeActions.setReportAssetsOperationInProgress(true));

    try {
        const clientId = getSelectedClientId(getState());

        assetType === REPORT_ASSET_TYPE.PLATFORM ?
            await dispatch(rerunReportPlatform(clientId, reportId, assetId)) :
            await dispatch(rerunReportInstance(clientId, reportId, assetId));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportAssetsOperationInProgress(false));
    }
};

export const loadReportAssets = (reportId) => async (dispatch, getState) => {
    dispatch(storeActions.setReportAssetsLoadStatus(LOAD_STATUS.LOADING));
    dispatch(storeActions.setReportAssets({}));

    try {
        const clientId = getSelectedClientId(getState());
        const data = await service.api.getReportAssets(clientId, reportId);

        dispatch(storeActions.setReportAssets(data));
    } catch (err) {
        showErrorSnackbar(getErrorText(err));
    } finally {
        dispatch(storeActions.setReportAssetsLoadStatus(LOAD_STATUS.LOADED));
    }
};

export const reportAssetsListSort = (assetType, column) => (dispatch, getState) => {
    const key = assetType === REPORT_ASSET_TYPE.PLATFORM ?
        'platformsTable' : 'instancesTable';
    const { [key]: { sortBy, sortOrder } } = selectors.getReportAssetsData(getState());

    if (column === sortBy) {
        const order = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;

        dispatch(storeActions.setReportAssetsTableSortOrder({ assetType, sortOrder: order }));
    } else {
        dispatch(storeActions.setReportAssetsTableSortColumn({ assetType, sortBy: column }));

        if (sortOrder !== SORT_ORDER.ASC) {
            dispatch(storeActions.setReportAssetsTableSortOrder({ assetType, sortOrder: SORT_ORDER.ASC }));
        }
    }
};

export const getAdverityAuthorizationsByDatastreamType = (datastreamTypeId) => async (dispatch, getState) => {
    const storeState = getState();
    const authorizationsMap = selectors.getReportFormAdverityAuthorizations(storeState);

    if (authorizationsMap[datastreamTypeId] === undefined) {
        dispatch(storeActions.setReportFormAdverityAuthorizations({ datastreamTypeId, items: null }));

        try {
            const clientId = getSelectedClientId(storeState);
            const list: Array<ingestionApiDefinitions['AuthorizationsByDatastreamTypeDto']> = await service.api.getAdverityAuthorizationsForReportingByDatastreamType(clientId, datastreamTypeId);
            const items = list
                .reduce((acc, { authorizations }) => {
                    if (authorizations?.length) {
                        return acc.concat(authorizations);
                    }

                    return acc;
                }, [] as Array<ingestionApiDefinitions['AuthorizationDto']>)
                .sort(({ name: name1 }, { name: name2 }) => (name1! > name2! ? 1 : -1));

            dispatch(storeActions.setReportFormAdverityAuthorizations({ datastreamTypeId, items }));
        } catch (err) {
            dispatch(storeActions.setReportFormAdverityAuthorizations({ datastreamTypeId, items: [] }));
            showErrorSnackbar(getErrorText(err));
        }
    }
};
