export const SET_REPORTS_LIST_ITEMS = 'reports/SET_REPORTS_LIST_ITEMS';
export const APPEND_REPORTS_LIST_ITEMS = 'reports/APPEND_REPORTS_LIST_ITEMS';
export const SET_REPORTS_LIST_PAGE = 'reports/SET_REPORTS_LIST_PAGE';
export const SET_REPORTS_LIST_COUNT = 'reports/SET_REPORTS_LIST_COUNT';
export const SET_REPORTS_LIST_SORT_COLUMN = 'reports/SET_REPORTS_LIST_SORT_COLUMN';
export const SET_REPORTS_LIST_SORT_ORDER = 'reports/SET_REPORTS_LIST_SORT_ORDER';
export const SET_REPORTS_LIST_LOAD_STATUS = 'reports/SET_REPORTS_LIST_LOAD_STATUS';
export const SET_REPORTS_LIST_SEARCH_TEXT = 'reports/SET_REPORTS_LIST_SEARCH_TEXT';
export const SET_REPORTS_LIST_OPERATION_IN_PROGRESS = 'reports/SET_REPORTS_LIST_OPERATION_IN_PROGRESS';
export const RESET_REPORTS_LIST = 'reports/RESET_REPORTS_LIST';
export const SET_REPORT_DETAILS = 'reports/SET_REPORT_DETAILS';
export const SET_REPORT_DETAILS_LOAD_STATUS = 'reports/SET_REPORT_DETAILS_LOAD_STATUS';
export const SET_REPORT_DETAILS_TABLE_SORT_COLUMN = 'reports/SET_REPORT_DETAILS_TABLE_SORT_COLUMN';
export const SET_REPORT_DETAILS_TABLE_SORT_ORDER = 'reports/SET_REPORT_DETAILS_TABLE_SORT_ORDER';
export const SET_REPORT_DETAILS_OPERATION_IN_PROGRESS = 'reports/SET_REPORT_DETAILS_OPERATION_IN_PROGRESS';
export const RESET_REPORT_DETAILS = 'reports/RESET_REPORT_DETAILS';
export const SET_REPORT_FORM_TEMPLATES = 'reports/SET_REPORT_FORM_TEMPLATES';
export const SET_REPORT_FORM_TEMPLATES_LOAD_STATUS = 'reports/SET_REPORT_FORM_TEMPLATES_LOAD_STATUS';
export const SET_REPORT_FORM_MARKETS = 'reports/SET_REPORT_FORM_MARKETS';
export const SET_REPORT_FORM_MARKETS_LOAD_STATUS = 'reports/SET_REPORT_FORM_MARKETS_LOAD_STATUS';
export const SET_REPORT_FORM_TEMPLATE = 'reports/SET_REPORT_FORM_TEMPLATE';
export const SET_REPORT_FORM_TEMPLATE_LOAD_STATUS = 'reports/SET_REPORT_FORM_TEMPLATE_LOAD_STATUS';
export const SET_REPORT_FORM_OPERATION_IN_PROGRESS = 'reports/SET_REPORT_FORM_OPERATION_IN_PROGRESS';
export const SET_REPORT_FORM_COLLECTOR_CREDENTIALS = 'reports/SET_REPORT_FORM_COLLECTOR_CREDENTIALS';
export const SET_REPORT_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS = 'reports/SET_REPORT_FORM_COLLECTOR_CREDENTIALS_LOAD_STATUS';
export const ADD_REPORT_PLATFORM = 'reports/ADD_REPORT_PLATFORM';
export const REMOVE_REPORT_PLATFORM = 'reports/REMOVE_REPORT_PLATFORM';
export const SET_REPORT_PLATFORMS = 'reports/SET_REPORT_PLATFORMS';
export const SET_REPORT_FORM_STAGE = 'reports/SET_REPORT_FORM_STAGE';
export const RESET_REPORT_FORM = 'reports/RESET_REPORT_FORM';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_COLLECTORS_LOAD_STATUS';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_MANIFEST_LOAD_STATUS';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_OPERATION_IN_PROGRESS = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_OPERATION_IN_PROGRESS';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DRAWER_OPEN = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DRAWER_OPEN';
export const SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DATA = 'reports/SET_REPORT_COLLECTOR_CREDENTIAL_FORM_DATA';
export const SET_REPORT_ASSETS = 'reports/SET_REPORT_ASSETS';
export const SET_REPORT_ASSETS_LOAD_STATUS = 'reports/SET_REPORT_ASSETS_LOAD_STATUS';
export const SET_REPORT_ASSETS_OPERATION_IN_PROGRESS = 'reports/SET_REPORT_ASSETS_OPERATION_IN_PROGRESS';
export const SET_REPORT_ASSETS_TABLE_SORT_COLUMN = 'reports/SET_REPORT_ASSETS_TABLE_SORT_COLUMN';
export const SET_REPORT_ASSETS_TABLE_SORT_ORDER = 'reports/SET_REPORT_ASSETS_TABLE_SORT_ORDER';
export const SET_REPORT_ASSET_STATUS = 'reports/SET_REPORT_ASSET_STATUS';
export const SET_REPORT_STATUS = 'reports/SET_REPORT_STATUS';
export const SET_REPORT_FORM_ADVERITY_AUTHORIZATIONS = 'reports/SET_REPORT_FORM_ADVERITY_AUTHORIZATIONS';
