import React, { Fragment, FunctionComponent, useEffect } from 'react';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getUserProfile } from 'modules/auth/selectors';
import { useAppDispatch } from 'modules/common/hooks';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import LoadingSpinner from 'modules/common/components/LoadingSpinner';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import ScrollableDrawer from 'modules/common/components/ScrollableDrawer';
import SortableHeader from 'modules/common/components/SortableHeader';
import { deleteReport, goToEditReportPage, loadReportDetails, reportPlatformsListSort } from '../../actions';
import { getReportDetailsDrawerProps } from '../../selectors';
import { ReportState } from '../../interfaces';

import local from './local.module.scss';

interface ReportDetailsDrawerProps {
    baseData?: {
        id: string;
        name: string;
        state: ReportState;
    };
    isOpen: boolean;
    setOpen: (value: boolean) => void;
}

const ReportDetailsDrawer: FunctionComponent<ReportDetailsDrawerProps> = ({ baseData, isOpen, setOpen }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isOpen && baseData?.id) {
            dispatch(loadReportDetails(baseData.id));
        }
    }, [ baseData, isOpen ]);

    const { data, dataLoadStatus, sortBy, sortOrder, isOperationInProgress } = useSelector(getReportDetailsDrawerProps);
    const { owners } = useSelector(getUserProfile);

    const isLoaded = dataLoadStatus === LOAD_STATUS.LOADED;
    const canManage = owners.some(({ permissions }) => permissions.includes(PERMISSIONS.COLLECTOR_CREDENTIALS.MANAGE_FROM_REPORT));

    let credentialNames: string[] = [];
    let authorizationNames: string[] = [];

    if (isLoaded) {
        credentialNames = data.platforms.map(({ platformName, collectors }) =>
            collectors.map(({ collectorName, odpIngestionApiCredentialName }) => {
                return `${platformName} - ${collectorName}: ${odpIngestionApiCredentialName || intl.formatMessage({ id: 'common.custom' })}`;
            })
        ).flat().sort();

        authorizationNames = data.platforms.map(({ platformName, collectors }) =>
            collectors.map(({ adverityAuthorizationName }) => adverityAuthorizationName ? `${platformName} - ${adverityAuthorizationName}` : null)
        ).flat().filter(Boolean).sort();
    }

    return (
        <ScrollableDrawer
            isOpen={isOpen}
            setOpen={setOpen}
            width={526}
            title={
                <Fragment>
                    {baseData?.name}
                    &nbsp;
                    <FormattedMessage id='common.details' />
                </Fragment>
            }
            buttons={
                <Fragment>
                    <div className='ls-button'>
                        <button
                            className='btn-flat'
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                    {
                        baseData?.id &&
                        <Fragment>
                            <div className='ls-button'>
                                <button
                                    disabled={!isLoaded || isOperationInProgress}
                                    className='btn-negative-transparent'
                                    onClick={() => {
                                        dispatch(deleteReport(baseData.id, setOpen));
                                    }}
                                >
                                    <FormattedMessage id='common.delete' />
                                </button>
                            </div>
                            {
                                canManage &&
                                <div className='ls-button'>
                                    <button
                                        disabled={!isLoaded || isOperationInProgress || !data.platforms.length}
                                        onClick={() => {
                                            dispatch(goToEditReportPage(baseData.id));
                                        }}
                                    >
                                        <FormattedMessage id='common.edit' />
                                    </button>
                                </div>
                            }
                        </Fragment>
                    }
                </Fragment>
            }
        >
            {
                isLoaded && !isOperationInProgress ?
                    <div className={local.contentContainer}>
                        <div className={local.templateName}>
                            {data.templateName}
                        </div>
                        {
                            data.platforms.length ?
                                <Fragment>
                                    <div className={classnames(local.platformRow, local.header, 'container-row')}>
                                        <SortableHeader
                                            id='platformName'
                                            title={<FormattedMessage id='reports.platform' />}
                                            sortBy={sortBy}
                                            sortOrder={sortOrder}
                                            sortCallback={reportPlatformsListSort}
                                            className={local.columnName}
                                        />
                                        <SortableHeader
                                            id='marketName'
                                            title={<FormattedMessage id='reports.market' />}
                                            sortBy={sortBy}
                                            sortOrder={sortOrder}
                                            sortCallback={reportPlatformsListSort}
                                            className={local.columnMarket}
                                        />
                                    </div>
                                    {
                                        data.platforms.map(({ platformName, marketName }, idx) => (
                                            <div key={idx} className={classnames(local.platformRow, 'container-row')}>
                                                <div className={local.columnName}>
                                                    <TextWithTooltip>{platformName}</TextWithTooltip>
                                                </div>
                                                <div className={local.columnMarket}>
                                                    {marketName || <FormattedMessage id='common.custom' />}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Fragment> : null
                        }
                        {
                            credentialNames.length ?
                                <Fragment>
                                    <div className={local.credentialsTitle}>
                                        <FormattedMessage id='common.credentials' />
                                    </div>
                                    {
                                        credentialNames.map(((credentialName, idx) => (
                                            <div key={idx} className={local.credentialsLine}>{credentialName}</div>
                                        )))
                                    }
                                </Fragment> : null
                        }
                        {
                            authorizationNames.length ?
                                <Fragment>
                                    <div className={local.authorizationsTitle}>
                                        <FormattedMessage id='common.adverityAuthorizations' />
                                    </div>
                                    {
                                        authorizationNames.map(((authorizationName, idx) => (
                                            <div key={idx} className={local.authorizationsLine}>{authorizationName}</div>
                                        )))
                                    }
                                </Fragment> : null
                        }
                    </div> :
                    <LoadingSpinner className={local.spinner} />
            }
        </ScrollableDrawer>
    );
};

export default ReportDetailsDrawer;
