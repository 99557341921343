import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';

import { useAppDispatch, useDidUpdateEffect } from 'modules/common/hooks';
import { LOAD_STATUS } from 'modules/common/constants';
import {
    CUSTOM_VALUE,
    INTERIM_REPORT_STATES,
    REPORT_FORM_FIRST_STAGE,
    REPORT_FORM_SECOND_STAGE,
    REPORT_FORM_THIRD_STAGE
} from '../../constants';
import { loadTemplate, resetReportForm, resetReportDetails } from '../../actions';
import { getReportDetails, getReportDetailsLoadStatus, getReportFormStage } from '../../selectors';
import FormFirstStage from './FormFirstStage';
import FormSecondStage from './FormSecondStage';
import FormThirdStage from './FormThirdStage';

interface ReportFormOwnProps {
    isEditMode: boolean;
}

type ReportFormProps = ReportFormOwnProps & InjectedFormProps<{}, ReportFormOwnProps>;

const ReportForm: FunctionComponent<ReportFormProps> = ({
    handleSubmit,
    change,
    isEditMode
}) => {
    const dispatch = useAppDispatch();

    const stageIndex = useSelector(getReportFormStage);
    const data = useSelector(getReportDetails);
    const dataLoadStatus = useSelector(getReportDetailsLoadStatus);

    const cleanup = () => {
        dispatch(resetReportDetails());
        dispatch(resetReportForm());
    };

    useEffect(() => {
        cleanup();

        return cleanup;
    }, []);

    useDidUpdateEffect(() => {
        if (isEditMode && dataLoadStatus === LOAD_STATUS.LOADED && !INTERIM_REPORT_STATES.includes(data.state)) {
            const { name, templateId, platforms } = data;

            if (name) {
                change('name', name);
            }

            if (templateId) {
                change('templateId', templateId);
                dispatch(loadTemplate(templateId));
            }

            platforms?.forEach(({ platformId, marketId, collectors }, idx) => {
                change(`platforms.platform_${idx}.platformId`, platformId);

                if (marketId) {
                    change(`platforms.platform_${idx}.marketId`, marketId);
                } else {
                    change(`platforms.platform_${idx}.marketId`, CUSTOM_VALUE);
                    change(`platforms.platform_${idx}.scheduleCustomChanges`, true);
                }

                collectors.forEach(({
                    collectorId,
                    odpIngestionApiCredentialId,
                    adverityAuthorizationId,
                    adverityAuthorizationName
                }) => {
                    if (odpIngestionApiCredentialId) {
                        change(`platforms.platform_${idx}.collectors.${collectorId}.credentialId`, odpIngestionApiCredentialId);
                    } else {
                        change(`platforms.platform_${idx}.collectors.${collectorId}.credentialId`, CUSTOM_VALUE);
                        change(`platforms.platform_${idx}.collectors.${collectorId}.customChanges`, true);
                    }

                    if (adverityAuthorizationId && adverityAuthorizationName) {
                        change(
                            `platforms.platform_${idx}.collectors.${collectorId}.adverityAuthorization`,
                            `${adverityAuthorizationId}.${adverityAuthorizationName}`
                        );
                    }
                });
            });
        }
    }, [ isEditMode, data, dataLoadStatus ]);

    if (INTERIM_REPORT_STATES.includes(data.state)) {
        return null;
    }

    let formStageComponent: ReactNode;
    switch (stageIndex) {
        case REPORT_FORM_FIRST_STAGE:
            formStageComponent = <FormFirstStage />;
            break;
        case REPORT_FORM_SECOND_STAGE:
            formStageComponent = <FormSecondStage isEditMode={isEditMode} change={change} />;
            break;
        case REPORT_FORM_THIRD_STAGE:
            formStageComponent = <FormThirdStage isEditMode={isEditMode} />;
            break;
    }

    return (
        <form id='reportForm' onSubmit={handleSubmit} autoComplete='off'>
            {formStageComponent}
        </form>
    );
};

export default ReportForm;
